import img from "../../../resources/images/dora-gregorio-glosario-contenido.png"

export const Article12 = () => {
    return (
        <div>
            <h2>INTRODUCCIÓN</h2>
            <p>Antes de comenzar a aplicar la Teoría General de Sistemas es necesario  indicar los significados de los términos del enfoque metodológico que pretende aplicarse. La teoría General de Sistemas es un enfoque metodológico aplicable a la ciencia, a los problemas concretos de cualquier ciencia. Joel de Rosnay lo caracterizó con un nuevo vocablo muy indicativo: "Macroscopio", que por oposición al microscopio que nos permite ver el microcosmos, haría referencia a un aparato para poder visualizar el macrocosmos, ampliaría la visión hasta un límite, al menos, planetario.</p>
            <p>Los problemas de la ciencia actual, una visión epistemológica de éstos, no estarían completos si no incluyera, junto a un esbozo de los últimos avances en materia de enfoque científico, un glosario de la nueva semántica que implican. Muchos de los problemas irresueltos con que se enfrentan los políticos, educadores, economistas, o especialistas en general, se debe a que omiten referirse a la Teoría General de Sistemas y a la Cibernética y no actualizan los nuevos significados que adquieren palabras de uso cotidiano en estos contextos; se provocan de esta manera discusiones que por lo general, son semánticas. Y el común de la gente relaciona estas disciplinas con la nueva tecnología computacional a la que sólo se accede mediante un ordenador.</p>
            <p>La sorpresa es grande cuando se les presentan estas disciplinas mucho más relacionadas con la Filosofia, vía epistemológica y metodológica, que con la mera técnica de la computación.</p>
            <p>Este glosario pretende ser un puente entre epistemología y teoría general de sistemas, y pretende tenderlo a partir de la experiencia cotidiana.</p>
            <p>Me ha parecido pertinente realizarlo a través de un cuestionario sencillo. Cada una de las preguntas intenta relacionarse con la siguiente de manera que se teja una red conceptual, que permita comprender aquello que incluye significados complejos, que se completan entre sí.</p>
            <p>1)¿Qué significa que la Teoría General de Sistemas asigna a la idea de "sistema" la importancia de ser una herramienta para interpretar la realidad?</p>
            <p>1° que permite entender el funcionamiento de las relaciones de interacción entre los elementos que forman la estructura de un sistema</p>
            <p>2° que establece el orden que preside esas relaciones</p>
            <p>3° que permite visualizar el vínculo entre cada sistema y el resto del universo</p>
            <p>2)¿Porqué no es lo mismo "sistémico" que "sistemático", cuál es el matiz?</p>
            <p>Denominamos "sistemático" a aquello que está ajustado a una manera de proceder invariable, o sigue una clasificación o taxonomía de acuerdo a ciertos fines.</p>
            <p>"Sistémico", en cambio, se denomina a todo lo relativo a la Teoría General de Sistemas, un enfoque metodológico para interpretar lo que se nos aparece como la realidad, en forma de situaciones complejas.</p>
            <p>3)¿Porqué no puede definirse a  un sistema como un "conjunto" de elementos "a secas", qué falta como característíca definitoria?</p>
            <p>Falta la característica de "organización", que está dada por la interacción entre los elementos.</p>
            <p>4)¿Porqué distinguir a un conjunto de elementos en interacción como sistema, es materia de decisión antes que de observación?</p>
            <p>Porque es el observador quien decide estudiar un conjunto de elementos en interacción como sistema; los elementos pueden pertenecer a infinitos conjuntos, y somos nosotros los que decidimos a qué nómina de variables  vamos a aplicarle el modelo sistémico.</p>
            <p>5)¿Cómo debe interpretarse en este contexto la palabra "interrelación"?</p>
            <p>Debe interpretarse como una disposición de los elementos (o nómina de variables) a interrelacionarse entre sí, o bien a la posibilidad que tienen de hacerlo.</p>
            <p>6)¿Qué es un sistema?</p>
            <p>1° Definición: Es una totalidad dinámica de elementos cuyas interacciones generan propiedades integrativas nuevas, no reductibles a las de sus componentes tomados aisladamente.</p>
            <p>(El interés de esta definición radica en el énfasis sobre las propiedades integrativas nuevas)</p>
            <p>Definición: Es una entidad relativamente autónoma, dotada de cierta permanencia y constituida por elementos interrelacionados que forman subsistemas estructurales y funcionales. Se transforma dentro de ciertos limites de t'stabilidad dinámica gracias a regulaciones internas que le permiten adaptarse a las variaciones de su entorno específico.</p>
            <p>[Esta definición implica que el sistema ha de ser identificable por su coherencia fenomenológica: ha de corresponder a un conjunto de observaciones relativas a una entidad claramente reconocible como tal. Ejemplos: cualquier ser viviente (planta, animal, bacteria), una empresa, un biótopo, una ciudad, una nación, una escuela, una institución. un sistema solar, etc.]</p>
            <p>Definición: Cualquier entidad conceptual o fisica constituída por partes interdependientes (R.Ackrofl)</p>
            <p>( La mención "conceptual o fisica" es interesante porque sugiere el parentesco entre la organización mental del hombre y la de su mundo circundante. Este tópico fue ya sugerido por Popper en "Conocimiento objetivo,,2)</p>
            <p>7)¿Qué es un sistema integrado?</p>
            <p>So Definición: Es una entidad autónoma, estructurada y con funcionalidad propia para el cumplimiento de sus objetivos. con una frontera que la coloca en relación selectiva con su entorno y dotada de estabilidad dinámica, dentro de una evolución irrevenible.</p>
            <h2>Desglose de conceptos en la definición de "sistema integrado":</h2>
            <p>8)¿Cómo debe entenderse la palabra "autonomía" en este contexto?</p>
            <p>Significa una cierta independencia respecto a su entorno, la cual le es permitida por lo que se denomina "reservas" o "variedad" del sistema. La "variedad" de un sistema es la reserva almacenada que le permite autonomía.</p>
            <p>(Por ejemplo, la autonomía o independencia de un país le es permitida por su variedad o reservas de energía, materia e información. de uso no inmediato gracias a lo cual puede compensar las variaciones impuestas por el entorno con el que interactúa. Si se trata de su autonomía o independencia económica ésta le es permitida por la reserva de divisas o variedad que respalda al sistema económico (en una época era el oro), y que permite su funcionamiento pese a las variaciones del metasistema económico con el que interactua)</p>
            <p>Ejercicio de aplicación:</p>
            <p>Planteo:</p>
            <p>Usted tiene en su monedero una moneda de 50 centavos, otra de 25 centavos y tres monedas de 10 centavos. 1)¿Tendría en tal caso la variedad suficiente para el pago exacto de un viaje de determinados  centavos en el transporte colectivo?</p>
            <p>9)¿Qué significa "variabilidad" en este contexto del sistema integrado?</p>
            <p>No debe confundirse con "variedad" o "reserva". La variabilidad es la capacidad del sistema para reaccionar eficazmente y adaptane a las variaciones del entorno o a las fluctuaciones de su medio interno utilizando para ello su variedad o reservas. Es la capacidad que se pone en juego para hacer frente a estas variaciones. Pero esta capacidad requiere echar mano de la variedad o reservas, y si no las hay, no puede ejercerse, es inútil.</p>
            <p>(Ejemplos: 10 huir eficazmente(variabilidad) requiere velocidad(variedad), y la velocidad se ha adquirido mediante algún entrenamiento. 20 Levantar un gran peso(variabilidad) requiere fuerza(variedad). 30 Sobrevivir con la mitad del sueldo mensual de cobro anticipado(variedad) los 30 días del mes requieren de la capacidad (variabilidad) que tenga para adaptar mi presupuesto y distribuir el sueldo durante los 30 días, que es mi reserva o variedad.</p>
            <p>10)¿A qué llamamos "variación"?</p>
            <p>La variación es la modificación de las condiciones externas o internas al sistema (entorno e intorno). Cuando la variación es demasiado grande o prolongada, las reservas o variedad del sistema se agotan. y la variabilidad o capacidad del sistema para echar mano de las reservas en forma eficaz, queda sin recursos. El sistema puede desaparecer o transformarse en otro sistema, o entrar a formar parte de otro sistema.</p>
            <p>Ampliemos con esta noción los ejemplos anteriores de la pregunta 9: </p>
            <p>1° huir eficazmente(variabilidad) requiere velocidad(variedad) que se ha adquirido mediante entrenamiento, pero la huida tuvo que hacerse en subida ya que se entró en un terreno montaftoso(variación); por lo tanto, se disminuyeron hasta agotarse las reservas o variedad (velocidad), y la capacidad para echar mano eficazmente a esa variedad (variabilidad) no alcanzó a ser eficaz.</p>
            <p>2° levantar un gran peso eficazmente (variabilidad) requiere fuerza (variedad o reservas), pero si tiene que hacer frente a alguna variación del entorno, por ejemplo que se disminuya la cantidad de oxígeno en el aire en el gimnasio, se requiere que eche mano de las reservas o variedad, la fuerza, y que, a su vez, se utiliza eficazmente (variabilidad) la cantidad de aire oxigenado que el organismo ha almacenado, que se disminuirá hasta agotarse si persiste la variación o falta de oxígeno en el entorno con el que interactua. 3° sobrevivir con la mitad del sueldo mensual anticipado(variedad) requiere de la capacidad o variabilidad suficiente para echar mano eficazmente de esa reserva o variedad. Pero si se produce una variación de las condiciones externas, por ejemplo una suba indiscriminada de precios de los alimentos, puede agotarse la reserva o variedad, y tendré que aguzar mi capacidad de reaccionar (variabilidad) frente a esta variación en el entorno, por ejemplo, acudir a un lugar de la economía informal o trueque para ofrecer algún tipo de objetos que produzca, y cambiarlos por alimentos, para poder administrar la reserva o variedad ( lo que me queda de la mitad de mi sueldo.</p>
            <p>Veámoslo en el ejemplo que ilustra la noción de "autonomía" de la pregunta 8: La independencia o autonomía de un país se conserva gracias a sus reservas o variedad de energia, materia e información de uso no inmediato que le permiten compensar las variaciones impuestas por el entorno o metasistema con el cual interacWa. Pero, si las variaciones son muy grandes tendrá que apelar a la capacidad de los elementos que lo componen, que pueden ser subsistemas económicos, políticos, institucionales, etc., subsistemas que representan los elementos del país dotados de la capacidad ( variabilidad) para reaccionar y compensar eficazmente las variaciones impuestas por el entorno con el que se interactúa, echando mano eficazmente a las reservas de todo tipo (por ejemplo, humanas, energéticas, materiales o de información calificada Pero si los subsistemas son ineficaces porque no poseen variabilidad para echar mano de las reservas o variedad, el sistema país puede desintegrarse, transformarse en otro sistema, o entrar a formar parte de otro sistema país.</p>
            <p>En el Ejercicio planteado en la pregunta 8 (ocho): Además de la variedad o reserva que necesitaria para afrontar el pago exacto del omnibus, también tendria que tener la variabilidad o capacidad suficiente para afrontar ese pago exacto,</p>
            <p>1°¿Cómo pondría usted enjuego su variabilidad?</p>
            <p>2°, Si hubiera una variación en el entorno con el que interactúa, por ejemplo, una suba en la tarifa de 65 centavos a 95 centavos, ¿tendria la variedad suficiente para que su capacidad o variabilidad para echar mano de su reserva le fuera suficiente?</p>
            <p>11 )¿A qué llamamos "estructura" y "función" de un sistema?</p>
            <p>En la pregunta 3 introdujimos la palabra "organización" como earacteristica definitoria de un sistema, ya que un sistema no es un mero conjunto de elementos. Los elementos de un sistema interacruan según ciertas relaciones que configuran una organización. Lo que facilita y conserva esa organización es lo que denominamos "estructura": esel orden en que se hallan distribuidos los elementos del sistema. Por ejemplo, en una curtiembre el sector de faenamiento será distinto al sector de sacado., el sector de ventas estará en otro lugar al de almacenamiento, pero cada uno tendrá un órgano de dirección y control, y todos los sectores conformarán un organigrama que los dividirá en niveles de jerarquía, organigrama que a su vez, estará supeditado a una dirección general. De manera que, son las relaciones por las que interactúan los elementos del sistema las que forman su estructura .</p>
            <p>La "función" es el tipo de actividad que le es propia a un elemento de un sistema en interacción con los demás; un elemento puede ser un subsistema. Cada elemento de la estructura es de una manera y se halla situado en el sistema de acuerdo a la función que le compete. Por ejemplo, en el ser humano, las placas óseas que recubren el cerebro y sus resistentes soldaduras dentadas, tienen la función de protegerlo ya que es el órgano privilegiado de control de todo el organismo Según la evolución de los seres orgánicos, la primera vértebra de los vertebrados se fue modificando y agrandando a medida que así se lo fueron exigiendo las funciones superiores del cerebro.</p>
            <p>Según la interpretación del estructuralismo o del funcionalismo, será más importante las estructura o la función respectivamente. En Ciencias Sociales la cuestión es muy ardua. Sistémicamente carece de utilidad plantearse esta cuestión. cuál de las dos caras de la misma moneda es más importante, ya que sin estructura no hay función y sin función la estructura desaparece. Es interesante, en la actualidad plantearse esta cuestión visualizando al Senado argentino como subsistema del poder legislativo, ya que ha desaparecido un "funcionario" de un lugar jerárquico importante .... ¿ha desaparecido la función o la estructura?</p>
            <p>La diferencia entre el carácter estático de la estructura y el carácter dinámico de la función es relativa ya que la estructura es un proceso lento que nuestra escala temporal no capta (gracias a la tecnología podemos ver lo que sucede cuando se forma la estructura de una flor que se abre), ya que apreciamos el tiempo en la medida de la especie Homo sapiens. La conclusión es que cualquier proceso en un tiempo muy breve es una estructura y toda estructura en un tiempo muy lento para nuestra apreciación es un proceso de cambio y transformación.</p>
            <p>12)¿Cómo pueden ser las relaciones internas que determinan la estructura de un sistema, cuyos elementos son subsistemas?</p>
            <p>Algunas relaciones son "simétricas" cuando los subsistemas se influyen recíprocamente, y otras "asimétricas" cuando determinan jerarquías dentro del sistema: si el subsistema A determina asimétricamente la acción del sub sistema B, el subsistema A es superior al B que es inferior dentro del sistema. A su vez los sistemas llamados cibernéticos" lo son porque tienen subsistemas de control cuya función es determinar las funciones de los subsistemas de nivel inferior. Podemos hacer el ejercicio de pensar cuál es la frontera de un sistema cuyo organismo de control de sus subsistemas son algunos de los organismos internacionales.</p>
            <p>13)¿Qué es la finalidad de un sistema?</p>
            <p>No se debe apelar al sentido común ni al significado cotidiano con que usamos la palabra "finalidad", ya que el lenguaje natural la entiende como finalidad consciente e intencional y en muchos casos declarada, que parece ser propia y exclusiva de los sistemas humanos. En el contexto de la T.G.S. la finalidad es el objetivo o meta que el sistema parece perseguir, pese a las modificaciones o variaciones de su entorno, y esta meta es revelada a la observación por el comportamiento efectivo, permanente y/o recurrente del sistema que parece tender a cumplir el objetivo de alcanzarla. Si un grupo de funciones se mantiene, pese a muy diversas variaciones, con la misma tendencia , se puede identificar como la finalidad del sistema, ya que en la práctica se comporta "como si" hipotéticamente fuera consciente y persiguiera deliberadamente ese objetivo.</p>
            <p>Finalidad de la mayoría de los sistemas vivientes: parece ser perpetuar la especie, ya que la mayoría de los mecanismos de adaptación da la impresión de tender a ese objetivo y determina las funciones de los subsistemas que los componen.</p>
            <p>En los sistemas sociales es complicado identificar la finalidad, en primer lugar habría que identificar si estamos considerando una sociedad como un sistema o como un subsistema, ya que podríamos así identificar la frontera más próxima que lo separa del entorno o metasistema con el que interactua, y de esa manera determinar el ámbito de su comportamiento. Y, en segundo lugar, los sistemas sociales suelen hacer explícita su finalidad, aunque a veces se confunden con su intencionalidad, y no siempre (o en la mayoría de los casos) coinciden, pues suponen perseguir un objetivo, aunque están colaborando con las metas de otro sistema social que no es el propio.</p>
            <p>14)¿A qué llamamos "frontera" de un sistema?</p>
            <p>La frontera de un sistema es lo que permite establecer la relación dentro-fuera, hace al sistema discernible del entorno. Muchas veces la frontera no es fácil de identificar, en especial en los sistemas sociales, en los que depende de decisiones lingüísticas y/o pragmáticas, por ejemplo, las embajadas de un Estado, en donde ya la frontera no es territoríal. La frontera es la región que separa y conecta al sistema con su entorno, su función es seleccionar los ingresos y los egresos. Hay que recordar que la metodología sistémica se adopta, es materia de decisión más que de observación, y en cuanto a las fronteras tanto naturales como artificiales (y éstas son las que se tracen arbitraria o fortuitamente) lo único que permite reconocerlas es su función selectiva con respecto a ingresos y egresos y su resistencia a las transformaciones.</p>
            <p>15)¿Cómo definimos "entorno"?</p>
            <p>Hasta ahora hemos nos hemos referido a la noción de entorno como el medio que rodea al sistema que puede ser un sistema mayor con el cual interaetúa y recibe el nombre de "metasistema".</p>
            <p>16)¿A qué denominamos "sistema abierto"?</p>
            <p>A aquellos sistemas que intercambian energía, materia e información con su entorno, o de cualquier combinación de las tres. Por ejemplo, un automóvil.</p>
            <p>17)¿A qué denominamos "sistema cerrado"?</p>
            <p>A aquellos sistemas que no intercambian, según R. Guibourg. Por lo tanto, carecerían de entorno. François en su Diccionario de TGS y Cibernética  cita a Cereijido, investigador sistémico que se dedica a investigar el intercambio de las membranas neuronales, en el INP de Méjico.  Él opina que un sistema abierto es el que intercambia energía y matería, pero no alude a la información. François piensa que ha de ser porque considera a la información como una forma o modalidad de la energía, lo cual es sumamente interesante, dada su especialidad (se dedica a las menbranas que recubren las neuronas, y a todos los tipos de "outputs" que permiten las salidas que tiene lo que parece ser su frontera. Pero, ofrece como ejemplo de sistema abierto la estufa de gas, y de sistema cerrado la estufa eléctrica. Y define al sistema cerrado como el que intercambia sólo energía con su entorno.</p>
            <p>18)¿A qué llamamos "sistema aislado"?</p>
            <p>Un sistema aislado es el que no intercambia ni materia ni energía con su entorno, según François. Esta definición coincide con la de sistema cerrado de Guibourg, que no considera a los sistemas aislados. François opina que un sistema aislado es una abstracción, ya que no es observable.</p>
            <p>19)¿A qué llamamos "sistemas reales"?</p>
            <p>A aquellos sectores de lo que, para nosotros, es la "realidad", y que decidimos enfocarlos para su estudio como sistemas.</p>
            <p>20)¿Qué significa que un sistema real es "abierto-cerrado"?</p>
            <p>Que son abiertos pero no completamente, y cerrados pero selectivamente: reciben influencia del resto del universo, pero sólo a través de ciertas vías específicas llamadas "entradas", y ejercen influencia sobre el resto del universo, pero sólo a través de ciertas vias específicas llamadas "salidas".</p>
            <p>21)¿Cómo definimos"entradas" de un sistema y lo diferenciamos de "inputs" o "ingresos"?</p>
            <p>Las "entradas"de un sistema son ciertas vías específicas por las cuales recibe o se producen los "inputs" o "ingresos".</p>
            <p>22)¿Cómo definimos "salidas" de un sistema y lo diferenciamos de "output"?</p>
            <p>Las "salidas" de un sistema son ciertas vías específicas por las cuales ejercen o se producen los "outputs" o "egresos".</p>
            <p>23)¿A qué llamamos "sistema estático"?</p>
            <p>Ricardo Guibourg nos dice: "Solemos llamar "estático" a lo que está quieto. Por lo tanto, un "sistema estático" es un sistema que sólo puede concebirse en el plano de lo abstracto, ya que el universo "real" se mueve y evoluciona constantementes. Los "sistemas estáticos" consisten en conjuntos de enunciados que poseen entre sí ciertas relaciones lógicas".</p>
            <p>Dentro de los sistemas de este tipo están los "sistemas deductivos formales" ( más allá de los sistemas axiomáticos de las ciencias formales, en los cuales, a partir de ciertas proposiciones "admitidas de antemano como verdaderas llamadas axiomas se infieren lógicamente otras proposiciones llamadas teoremas). En la actualidad, los sistemas deductivos formales y formalizados de deducción natural son los que han llevado al desarrollo exponencial de la lógica, cuyos sistemas desempeñan el papel de matrices por analogía con los modelos de sistemas.</p>
            <p>4 Ricardo Guibourg "Nuevos Enfoques Metodológicos: Ciencia, Cibernética y Sistema", Módulo 5, separata de "UBA XXI" Introducción al Pensamiento Científico", CBC, UBA.</p>
            <p>5 Conviene que nos preguntemos cuál es nuestra concepción de "lo real" en este contexto, dadas las premisas con las Guibourgjustifica porqué llamamos "estáticos" a los sistemas que sólo se dan en el plano de "lo abstracto": solemos llamar "estático" a lo que está quieto"> y "el universo real se mueve y evoluciona constantemente" Se desprende de aquí que Guibourg considera que "lo real" no tiene nada de abstracto, ya que "lo abstracto" es quieto. Como también se desprende que los sistemas axiomáticos y/o los sistemas deductivos formales no son reales!</p>
            <p>6 Lo entrecomillado y sin negrita en el párrafo marcado por la nota al pie 6 de la pregunta 22, que pertenece a la op. cit. de Guibourg, es lo que produce la limitación y estrechez del análisis de la lógica axiomática. Esa limitación, que restringe su aplicación a la ciencia empírica, es llamada tautología. Hoy día, la deducción axiomática ya se ha abandonado (por antinatural) por otros sistemas deductivos de deducción natural (llamada intuitiva por Gregory Bateson) en los cuales no se parte de leyes (forma de proposiciones lógicamente verdaderas) sino de reglas de inferencia (formas de razonamiento válidas elementales) que incluyen supuestos, es decir, proposiciones que no se sabe si son ciertas, pero que se incluyen para comenzar a deducir. En algunos casos los supuestos llevan con éxito a a la conclusión, y en otros casos se cancelan cuando las subderivaciones a partir de ellos lleva a contradicciones.</p>
            <p>O sea, se demuestra la incompletitud y lo probabilístico como propiedades metateóricas de los sistemas de la lógica de uso actual en la metodología cientifica; en oposición a la lógica lineal, completa y determinística de los anteriores sistemas deductivos axiomáticos, que mostraron su estrechez para la metodología de la ciencia empírica.</p>
            <p>El significado de la frase "sistema dinámico" nos indica que se trata de entidades estrechamente relacionados con lo concreto, y que son temporales, aunque no solo pueden estar compuestos por enunciados sino también por cualquier tipo de objetos, personas, mezclas de distintas cosas, y/o subsistemas; incluyen mecanismos de movimiento, cambio y/o evolución.</p>
            <p>Ejemplos:</p>
            <p>-Un sistema político, que rige las relaciones dinámicas entre grupos de individuos, y que aparece en un momento histórico y desaparece en otro.</p>
            <p>-Un sistema ecológico que puede llegar a extinguirse por la depredación que hace el hombre de algunas de sus especies, animales o vegetales, o por la destrucción o fragmentación de su "habitat".</p>
            <p>-La Tierra, donde nuestras acciones están produciendo extinciones masivas por la contaminación del agua, por ejemplo.</p>
            <p>Pero el interés de la T.G.S. no gira alrededor de cualquier clase de sistemas dinámicos. Los que constituyen el paradigma de la noción de sistema, son los sistemas integrados, que es un caso especial de sistema dinámico.</p>
            <p>24)¿Cómo puede describirse un sistema integrado?</p>
            <p>Haremos uso del desglose de la definición de sistema integrado ofrecida en la respuesta de la pregunta 7 de este Glosario, donde lo caracterizábamos como una entidad autónoma, estructurada y con funcionalidad propia para el cumplimiento de sus objetivos, con una frontera que la coloca en relación selectiva con su entorno específico, y dotada de estabilidad dinámica dentro de una evolución irrevenible. En las respuestas a las preguntas 8, 9, 10, 11 y  12 relacionamos el concepto de "autonomía" con los de "variedad", ''variabilidad'' y ''variación'', describimos estructura-función, "finalidad", "frontera", "entorno" e incluímos "entradas" y "salidas" relacionadas con ambas .. Continuamos ahora con:</p>
            <p>-Ingreso, proceso, egreso</p>
            <p>Anteriormente hemos introducido la palabra inglesa "input" para hablar de ingreso. Los objetos que ingresan o egresan de un sistema integrado se consíderan relevantes cuando producen alguna modificación en el sistema, o irrelevantes cuando no inciden en el funcionamiento del sistema. Los objetos relevantes son considerados beneficiosos cuando hacen crecer, desarrollar, y/o lo estabilizan dentro de ciertas proporciones al sistema; o bien perjudiciales si tienden a alejar al sistema del punto de equilibrio o a hacerlo desaparecer como sistema.</p>
            <p>Ejemplos:</p>
            <p>En este momento del país, dejo la búsqueda de ejemplos de estas nociones en el sistema político argentino, a los lectores.</p>
            <p>Hablamos de proceso cuando se producen los cambios que transforman a los ingresos en egresos. El proceso está relacionado con la finalidad que es el objetivo que parece perseguir el sistema, hasta producir alguna influencia en el entorno cuando el ingreso se transforma en egreso; pero si ingreso y egreso resultaran idénticos (es decir sin proceso) el sistema carecería de finalidad pues no habría ninguna influencia o cambio en el entorno. Los sistemas procesan materia, energía e información, y las devuelven o egresan hacia el entorno bajo otra forma y en otro lugar.</p>
            <p>Ejemplos:</p>
            <ul>
                <li>Una universidad transforma bachilleres o estudiantes de enseñanza media en profesionales que ejercerán influencia por la información que han ingresado y transformado a través de un proceso (en el mejor de los casos).</li>
                <li>Un panal de abejas transforma lo que ingresan sus integrantes y lo egresan transformado en miel a través de un proceso.</li>
                <li>El sistema de un aparato electrolítico transforma el agua que le ingresa, la procesa y la devuelve transformada en en los gases hidrógeno y oxígeno.</li>
            </ul>
            <p>En los seres vivos el ingreso o recepción de los alimentos provenientes del entorno se denomina anabolismo, al proceso de transformación y aprovechamiento, se lo denomina metabolismo, y catabolismo, con la descomposición de los desechos, a la expulsión hacia el entorno.</p>
            <p>7 Antes de considerar qué se entiende por sistemas dinámicos,  tendríamos que hacer una disgresión acerca de la pareja de conceptos aparentemente opuestos, o mejor, que el sentido común concibe opuestos: abstracto-concreto, pero dado que esa disgresión escapa a los límites de este Glosario, y que además, llevaría a quien escribe a lo que es motivo de sus actuales investigaciones bibliográficas que van desde Piaget y su psicología y epistemología genéticas, a Karl Popper, P.Vendryes y G.H.Miller, y hasta los neurofisiólogos y matemáticos Warren McCulloch y Walter Pitts, al antropólogo Gregory Bateson, y a H.Maturana, la autopoiesis y la termodinámica de los sistemas irreversibles (por nombrar sólo algunas de las hipótesis involucradas) creo oportuno dejar la digresión,  para una más propicia oportunidad.</p>
            <img src={img} alt={"ilustración de un individuo inflando un globo"} style={{boxShadow:"0 0 30px 5px #ffffff", border:"0px solid white"}}/>
            <p>Una esfera apoyada sobre una superficie plana y horizontaL La esfera se quedará quieta en cualquier parte de la superficie, y si no la movemos se quedará donde la pongamos. Es el equilibrio indiferente.</p>
            <p>Si la esfera está apoyada sobre una superficie cóncava la esfera se alojará en el punto más bajo de la superficie y si la movemos un poco tenderá a volver al mismo sitio. Es el equilibrio estático.</p>
            <p>Si la superficie donde la colocamos e convexa hacer que la esfera se quede será muy dificil, no conseguiremos el experimento a menos que tengamos mucha paciencia para que se quede en el punto más alto pero al más leve movimiento se desplazará. Se trata del equilibrio inestable.</p>
            <p>Pero los sistemas tienen otra clase de equilibrio que depende del movimiento y, por eso, se llama equilibrio dinámico.</p>
            <p>Es el caso de un individuo que está inflando un globo. A medida que sopla el globo se hincha. Entre soplo y soplo el globo se encuentra en equilibrio estático, pues las presiones de adentro del aire y la presión de la goma se encuentran compensadas. Si imaginamos que el globo tiene un pequeño orificio en el lado opuesto del lado que el individuo le insufla aire, normalmente seguirá así inflado el el globo y no explotará; si el individuo se entusiasma y sopla mucho y entra mucho más aire del que sale el globo explotará al quebrarse la resistencia de la goma, pero si la entrada y la salida son equivalentes el globo permanecerá inflado indefinidamente. El equilibrio dinámico es esa compensación de los cambios, permanencia de estructuras y funciones en medio del flujo de movimientos.</p>
            <p>Pero el globo puede fluctuar dentro de ciertos límites, ya que a cierta distancia del punto ideal del equilibrio aparecen los puntos críticos, y al llegar allí se pierde el equilibrio.</p>
        </div>
    )
}