export const Article2 = () => {
    return (
        <div>
            <p className={"align-center"}>Fuente: Kliksberg , Bernardo. <b>Más ética, más desarrollo.</b> 6 Ed.</p>
            <p className={"align-center"}>Bs. A., Temas, 2006. 224 p.</p>
            <h2>LA SITUACIÓN ACTUAL</h2>
            <h3>América en Crisis</h3>
            <p>América Latina evidencia una población víctima de pobreza paradojal. Dotada de extraordinarios recursos naturales y altos niveles de producto bruto, muestra situaciones de impactantes falencias:</p>
            <ul>
                <li>40% de poblaciones urbanas mayores de 65 años carecen de ingresos.</li>
                <li>La República Argentina, que exporta alimentos para más de 300 millones de personas muestra, en el conourbano bonaerense, problemas de desnutrición.</li>
                <li>Los países andinos, con mayores reservas de petróleo que Estados Unidos, el 25% de carbón del mundo y el 20% de los recursos hídricos del planeta, padecen penurias parasu supervivencia.</li>
            </ul>
            <p>La desigualdad pareciera ser el factor disparador; unido ello a un Estado con frecuencia ausente frente a estos grandes problemas, niveles de corrupción en todas sus formas y no vigencia de valores éticos, que atiendan a la equidad y la política de servicio. “Quien no vive para servir no sirve para vivir”, reflexiona Teresa del Calcuta.</p>
            <p>Al presente, el gobierno argentino enfatiza en la inversión social. La desigualdad marcada, la marginalidad, los niños en situación de calle, la discriminación extendida de género y las crisis de las familias en su desempeño como “útero social” constituyen algunos de los desafíos éticos en vigencia. Un desarrollo sustentable a la sombra de un humanismo sostenido pareciera ser la salida requerida.</p>
            <h2>Otros enfoques, vigentes en nuestra tierra</h2>
            <p>Noruega ostenta su transparencia administrativa; Finlandia, con la tasa de presos más baja de Europa. Suecia, con su devoción a la cultura del “Slow Down” (actitud laboral sin prisa); Canadá, y su sistema de salud totalmente inclusivo: son éstos algunos de los ejemplos sobre la calidad posible.</p>
            <p>Se requiere, en especial, impulsar círculos virtuosos que garanticen, en democracia, el derecho a la alimentación, la salud, la educación y el trabajo. Frente a la globalización que todo lo penetra, el Romano Pontífice (Juan Pablo II) reclamó, en su oportunidad, un código ético que respalde al ser humano en estos avatares.</p>
            <p>Joseph Stiglitz, Premio Nobel en Economía 2003, destaca la necesidad de una ética que apoye al capital social y los nuevos compromisos, dentro de la Especie y el Mundo, sobre el que incidimos.</p>
            <p>En palabras de Ilya Prigogine, Premio Nobel de Química (1977), la mayor parte de las estructuras que nos rodean y penetran son de índole disipativa y de final abierto. Para Edgard Morin (1991), por su parte el nombre del futuro es “INCERTIDUMBRE”.</p>
            <h3>La inequidad</h3>
            <p>La sociedad humana, víctima de la inequidad, clama al Cielo: 358 personas poseen, acumulada, una riqueza superior al 45% de la poseída por el total de la población mundial. Al capital comercial se debe sumar, de modo destacado, el capital social.</p>
            <h3>La realidad abordada con humildad</h3>
            <p>En el abordaje de la realidad bajo el ropaje de la Humildad (Joseph Stiglitz, 1998) se debe evitar, puntualmente, la confusión “medios-fines”. Corresponde agregarle, asimismo, el desarrollo social, la preservación de los equilibrios medio-ambientales; el promover la educación, la preservación y profundización de la democracia, la lucha contra la pobreza y la discriminación.</p>
            <h2>FRENTE A LA INEQUIDAD, INTENTOS Y EXPERIENCIAS EN LATINOAMERICA</h2>
            <h3>Perú. Villa “El Salvador”</h3>
            <p>De los arenales, a una experiencia social de avanzada. Sobre la base de un desierto arenoso, vecino a Lima, surge una población organizada en grupos residenciales que asciende a 300.000 habitantes. Organización en salud, cientos de kilómetros de redes de agua y luz, pistas-carreteras, colegios, mercados, zona agropecuaria y parque industrial.</p>
            <p>El capital social, hasta aquí latente, se disparó con el emprendimiento. Se cultivó la autoestima, y las actividades culturales formaron parte de la vida cotidiana.</p>
            <h3>Venezuela. Ferias de consumo familiar.</h3>
            <p>Redujeron el 40% e los precios de venta al público, sobre productos verdes. Venden, por kilogramo, productos frutihorticolas a precio único.</p>
            <p>Se privilegia la solidaridad, la responsabilidad personal y del grupo. También la transparencia en las relaciones, la iniciativa y el amor al trabajo.</p>
            <p>Las ferias, han resistido todos los pronósticos agoreros y se han transformado en una de las principales empresas mercantiles del país, dedicada a la alimentación. Se han promovido así, valores de presencia, latente en la sociedad civil.</p>
            <h3>Porto Alegre. Presupuesto Municipal Participativo</h3>
            <p>Su implementación ha desatado, en Brasil, la fiebre participativa. Setenta Municipios del país iniciaron pruebas similares.</p>
            <p>Argentina. Margarita Barrientos y los Piletones</p>
            <p>Vive y lidera su emprendimiento en la Villa Miseria de ese nombre, inserta en el Conurbano Bonaerense. Tiene doce hijos. Su marido, que colabora en la empresa, perdió un brazo a raíz de un accidente.</p>
            <p>Entre ambos, organizan un comedor popular subsidiado por la Comunidad; que alimenta 1.600 niños.</p>
            <h2>ALGUNAS ENSEÑANZAS RECIBIDAS SOBRE LA BASE DE ESTAS EXPERIENCIAS</h2>
            <p>Las experiencias señaladas y otras muchas, vigentes en el mundo han movilizado formas de capital no tradicionales. Mientras tanto, distintos sectores echan mano, por estos mecanismos, de diseños novedosos que evidencian una nueva concepción vigente en términos de valores.</p>
            <p>La Sociedad gestora aboga por la cultura compartida y la integración social, con refuerzo, indudable, del capital educativo hasta allí latente. En la práctica, esta renovación se constituye en un aporte efectivo a la integración familiar.</p>
            <h3>El Voluntariado</h3>
            <p>El voluntariado (los que hacen cosas por los demás) genera, en diversos países desarrollados, más del 5% del Producto Bruto Nacional. En encuestas realizadas entre voluntarios éstos expresaron que encaran estos aportes por el deseo íntimo de ayudar a otros y sentirse realizados como personas.</p>
            <h3>Síntesis</h3>
            <p>A despecho de fuertes resistencias e intereses, la participación comunitaria persiste y se abre camino. Mas allá de la subestimación como estigma, aplicada a los pobres y las manipulaciones con las que se agrede a la comunidad, disparadas por el “hambre de poder” de algunos, la participación social ha llegado para quedarse: esta conducta solidaria surge de la naturaleza misma del Hombre.</p>
            <p>Frente a cuadros sociales críticos; con desnutrición, desocupación y desintegración familiar, debemos bregar para incorporar e incluir con eficacia y protección a los marginados y excluídos. Ante la violencia múltiple, los “pactos de sangre” (ver conductas violentas de los maras en Centroamérica) que,  entre otras, se reiteran en nuestro medio, es importante que brindemos espacio humano y de mayor reflexión,  a todos.</p>
            <h2>Bibliografía</h2>
            <p>1. Constanza, Robert; y Otros. Una introducción a la economía ecológica. México, Ed. Continental, 1999. 302 p.</p>
            <p>2. García Díaz, Carlos J. La salud de los pobres en el enfoque bioético. (En: Revista Fundación Facultad de Medicina, vol. XIV Nº 51. Diciembre 2004. p 14-15).</p>
            <p>3. Kliksberg, Bernardo. Bases éticas de la economía. (En: La Nación, 131 -46175-: 19). 11/03/00.</p>
            <p>4. ————————. Paradojas inexplicables. (En: La Nación, 138 -48757-: 19). 26/06/07.</p>
            <p>5. ————————. La juventud excluida es una bomba de tiempo. (En: La Nación, 62 -22098-: 31). 11/07/07</p>
            <p>6. Ludueña, María Eugenia. Madres de la tierra. (en La Nación Revista -1965-, 4 de marzo de 2007, p. 18-27)</p>
            <p>7. Piombi, Renato. (Rotary Club de Belmont; Canadá) Cultura del “Slow Down” (actitud sin prisa). En: Vida Rotaria (398) p. 18-20. 2007.</p>
            <p>8. Polonio López, Begoña. Modelo de desempeño ocupacional de la Asociación Americana de Terapia Ocupacional. (en su: Concepto fundamentales de terapia ocupacional. Buenos Aires, Ed. Médica Panamericana, 2001, p.104-113.</p>
        </div>
    )
}