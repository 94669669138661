export const Article11 = () => {
    return (
        <div>
            <p>El escritor sueco Henning Mankell, en su libro “Arenas Movedizas”<a href={"#notas"}>[1]</a> desarrolla un interesante ejemplo de extinción de una cultura,  describiendo lo sucedido en la Isla de Pascua la cual, cuando fue descubierta en 1772, se encontraba desierta, pero con una gran cantidad de estatuas de sus dioses, algunas en pie, otras caídas y algunas sin terminar. <a href={"#notas"}>[2]</a></p>
            <p>Haré a continuación una síntesis de lo que dice Mankell que probablemente sucedió y luego una exposición también sintetizada y levemente modificada de un texto del importante sistemista Erwin Laszlo<a href={"#notas"}>[3]</a> el que, a mi entender, nos muestra que lo sucedido en esa lejana isla del océano Pacífico puede ser una importante lección para nuestro mundo actual.</p>
            <p>Dice Mankell:</p>
            <p>“No se sabe con certeza que pasó, pero las hipótesis son escasas: Parece que un día  quienes colonizaron la isla introdujeron, sin saberlo,  un puñado de ratas, las que se multiplicaron a toda velocidad y se alimentaron del fruto de las palmeras que cubrían  la región. Los bosques eran  grandes recursos para los isleños, pero se produjo la devastación forestal ya que los isleños talaron los árboles para utilizar la madera como combustible y también para llevar rodando las estatuas de sus dioses a sus emplaza–mientos .Cuando desaparecieron los árboles la tierra que usaban para la siembra desapareció barrida por el viento y los árboles no volvieron a crecer. Dejaron estatuas en las canteras sin terminar. No se sabe que pasó, no hay nada escrito, pero parece haber tenido lugar una hambruna y  lucha por los alimentos entre la población. No hay nada que pueda servir para comprender lo que pasó antes que la isla quedara nuevamente desierta.”</p>
            <p>Esto, pienso, nos sirve como ejemplo de lo que puede suceder a la humanidad actual porque, como señala Laszlo, hemos arribado ahora a lo que puede ser considerado la <i>“cúspide de la insostenibilidad”</i>, en la cual nuestro modo de vida ha generado amenazas inmanentes no solamente a nuestra propia existencia sino también a la del  ecosistema global .</p>
            <p>Dice Laszlo en dicho trabajo, publicado por la revista Systema, que desde la Segunda Guerra Mundial, a nivel del sistema socio-económico, los errores de nuestros sistemas culturales han creado incoherencia con la tierra viviente. Esto es peligroso porque el proceso evolucionarlo mantiene la viabilidad de los sistemas mediante un desarrollo meta-estable no lineal.</p>
            <p>La humanidad ha creado cada vez más sub-sistemas que han crecido a expensas del resto de los sistemas que la albergan. Los sistemas financieros y bancarios, junto con los grandes sistemas gubernamentales, crecen a expensas del resto. Esta tendencia hacia la concentración de recursos en pocas manos genera un sistema incoherente que se auto-reproduce.</p>
            <p>Esto es exactamente como se desarrolla un cáncer. Los sistemas humanos contemporáneos son coherentes en sí mismos <i>pero dentro de su contexto son cancerígenos.</i><a href={"#notas"}>[4]</a></p>
            <p>Lo que hay que hacer en forma positiva es volver a hacer coherente el super-sistema. Para crear estructuras que podamos incorporar en super-coherencia respecto del planeta, debemos volver a las intrínsecas relaciones internas de la vida. <i>Debemos recobrar nuestra pertenencia, nuestra integralidad con la red de la vida.</i></p>
            <p>La nueva cultura global debe estar basada sobre las , aparentemente esotéricas, nociones de amor, ser uno mismo, empatía y compasión. Debemos convertirnos en miembros conscientes de una red integral de vida en este planeta. <i>Debemos alejarnos de una dirección de la humanidad centrada en sí misma</i> y crear una cultura de coherencia y super-coherencia.</p>
            <p>El cambio requerido es una transformación cultural. Esto debe producirse a través de un cambio en nuestra conciencia – un cambio consciente en nuestros valores, percepciones y aspiraciones.</p>
            <p>La vida no es una anomalía ni un evento casual. En cambio, emerge cada vez que la coherencia lleva a un sistema super-coherente. Sin embargo, entra en riesgo cuando se topa con incoherencia sistémica. Es importante observar que el problema de la creciente incoherencia no es un problema competitivo – la competencia dentro de un sistema es saludable siempre y cuando no exceda los límites de los parámetros de viabilidad del sistema mismo.</p>
            <p>Los fenómenos cancerígenos exceden esos límites. No se puede mantener un cáncer, porque el sistema que lo sostiene será destruido por él.</p>
            <p>El cuadro de referencia para la viabilidad de la especie es la biosfera. Tenemos que mantener esto para mantenernos a nosotros. El sistema más grande es siempre el marco de referencia para la viabilidad de cualquier sistema.</p>
            <p>Los humanos son al mismo tiempo sistemas biológicos y sistemas culturales. Confiamos en la biosfera para sobrevivir, de modo que nuestra cultura debe ser compatible con sus límites operacionales. Cuando la cultura del sistema humano es, en sí mismo, el problema, tenemos que evolucionar la totalidad del modelo de las relaciones entre humanidad y ambiente.</p>
            <p><i>El foco actual de maximizar intereses de corto plazo es un modelo canceroso del sistema global humano.</i> Es funcionalmente equivocado, y si hemos de sobrevivir tenemos ahora que  desarrollar nuevos modelos  de relacionaros con nuestro super-sistema. El crecimiento de la población humana está aumentando la tensión sobre el super-sistema, pero el crecimiento de la población no es, en sí mismo, el problema. No es acerca de las cantidades, <a href={"#notas"}>[5]</a>sino acerca de las relaciones: el problema no es cuantos son, sino <i>que es lo que están haciendo aquellos que están ahí.</i></p>
            <p>Ya no podemos sobrevivir solamente emparchando el marco cultural de la humanidad con referencia a desarrollos locales. Estamos ahora en un punto en el cual tenemos que cambiar la orientación total de la humanidad, y esto es un problema cultural.</p>
            <p>Laszlo nos da las guías para ello.</p>
            <p>Ojalá sepamos usarlas.</p>
            <h2 id={"notas"}>Notas</h2>
                <p><span  style={{color:"var(--main-highlight-color)"}}>[1]</span> Editorial Tusquets,2015</p>
                <p><span  style={{color:"var(--main-highlight-color)"}}>[2]</span> Ibid pag 44</p>
                <p><span  style={{color:"var(--main-highlight-color)"}}>[3]</span> Revista Systema Volumen 2, 2014 (http://www.systema-journal.org/issue/view/22 pags 5-10.Information and Coherence in Nature; – and the Cancer of Human-World Incoherence) el que fue publicado integralmente en www.gesi.org.ar en traducción mía al castellano</p>
                <p><span  style={{color:"var(--main-highlight-color)"}}>[4]</span> Las cursivas son mías</p>
                <p><span  style={{color:"var(--main-highlight-color)"}}>[5]</span> Sin embargo, pienso que el problema de la cantidad de población (actualmente más de 7000 millones de seres humanos) también tiene una gran incidencia. El problema también es de cuantos somos…</p>
        </div>
    )
}