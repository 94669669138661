export const Article3 = () => {
        return (
        <div>
            <p>La visión sistémica, aplicada a la salud, muestra su presencia y penetración en el último tercio del siglo pasado. El esfuerzo tesonero y constante del Profesor Charles François en nuestro medio, contribuyó, sin dudas, a su inserción y arraigo.</p>
            <p>A la regulación biológica se suma la asistencia social, de alcances impensados, que impregna la lectura y fundamenta la elección y el instrumento. Se visualizan, así, y comprenden redes y centros, en densa interacción dentro del proceso de vida que asume, entonces, el liderazgo. Salud, como adaptación permanente y creativa a circunstancias oportunas, galvaniza el “in fieri” que nos identifica, incluidas miserias y posibilidades.</p>
            <p>La salud transcurre, sin duda, más acá y más allá de lo sanitario.</p>
            <p>La vida, y su universo siempre renovado de oportunidades, la Hipótesis GAIA, su vigencia y trascendencia; en síntesis: la compleja gama de interacciones y contactos.</p>
            <p>La lectura sistémica reverdece y afianza así, la interpretación sanitaria de nuestra realidad, en orden al sentido propio del existir y la calidad de vida, siempre personal e irreducible.</p>
            <p>La red causal, con sus instancias provocadoras y sus imprevistos, que desafian la razón y el sentido. El azar (¿o Dios?). El criterio siempre presente del abordaje holístico (incluída la caja negra) constituyen, entre otros, algunos de los desafio s de esta compleja maraña, de dificil acceso y comprensión simplificadora, reemplazada por el abordaje lógico y la metadisciplina; ambos comprensibles. La lectura acabada del conjunto, más allá de especialidades distorsionantes; aportes sustantivo s de la Torre de Babel: la cultura del caos y la incertidumbre, de moda y vigencia en nuestros tiempos. Pensamos que ha sonado la hora de reconstruir, con sentido, la lectura de conjuntos, también en salud, merced a la visión sistémica. Reinstaurada en sus alcances y “tozudamente vigente”.</p>
            <h2>Abstract</h2>
            <p>The sistemic vision, applied to health, shows its presence and its penetration in the last third of the 20th century. The perseverant and constant effort of Professor Charles Fran90is contributed, without any doubt, to its insertion and sett1ment.</p>
            <p>To the biological regulation was added the social attendance, of unexpectedreaches, that impregnates the reading and supports the election and the instrument. It can thus be visualized, and understood as networks and centers, in dense interaction within the life process that then assumes, the leadership. Health as a pennanent and creative adaptation to circumstances and opportunities, galvanizes, in fieri, that identifies us, miseries and possibilities included.</p>
            <p>Health passes, without doubt, before and beyond the sanitary.Life, in its always renewed universe of opportunities, the Gaia Hypothesis, its use and importance; in short:: the complex range of interactions and contacts.</p>
            <p>The systemic reading tums green again and strengthens, thus, the sanitary interpretation of our reality, related to the very sense of existence and the quality of life, always personal and irreducible.</p>
            <p>The causal network, with its provoking instances and its unforeseen effects, that challenges reason and the sense. The randomness (¿or God?). The always present criterion ofthe holistic approach (inc1uding the black box)engenders, among others, some ofthe challenges ofthis complex maze of difficult access, it simplifies understanding, replacing it with the logical approach and the metadiscipline; both comprehensible.</p>
            <p>The complete reading of the whole, beyond distorting specialties; substancial contributions of the Tower of Babel: the cuiture of chaos and the uncertainty, fashionable and in use in our times. We think that the hour has come to significantly reconstruct, the reading ofWholes, also in health, through the sistemic vision, restored in its reaches and “stubbomly prevailing”.</p>
            <h2>Abordaje Preliminar</h2>
            <p>Cuando el ser humano, en su acontecer, se vio sorprendido por la enfermedad, atinó a bucear en los orígenes del daño. Él estaba sano hasta la aparición del incidente: algo, ajeno y extraño, lo había invadido. No atinó a inculparse. Sí, a escapar de esa agresión incomprensible. El mal, la maldición, lo había abordado.</p>
            <p>Bajo la vigencia de lo sobrenatural, pronto accedió a la referencia divina. “De la peste, el hambre y la guerra,  líbranos Señor” musitó.</p>
            <p>Al evolucionar, inició la búsqueda de eventuales culpables presentes, y sus acciones perjudiciales. Temió a la brujería y anatematizó a los brujos.</p>
            <p>Con el correr del tiempo indagó, tras determinantes materiales, visibles e invisibles (submicroscópicos), Se sentía atado a los demás y a la propia tierra. Concluyó, por último, que podía promover la salud y prevenir la enfermedad.</p>
            <p>La prevención se transformó en específica, de forma creciente. Al progresar en el proceso, dio firme impulso a la calidad de vida, que hoy llega a tornarse accesible.</p>
            <p>Avanzado el siglo veinte, “tropezamos” con la visión sistémica y su nueva lectura de la realidad. En lo personal, el fenómeno ocurrió a fines de la década del sesenta. La nueva mirada me deslumbró y su visión generó vivencias admirables. Era otra realidad. Se integraba, y obtenía sentido. Era como acceder a la luz y  al salto cualitativo.</p>
            <p>El descubrimiento impulsó la búsqueda. Y, al final del camino, una tarde en la Sociedad Científica Argentina me topé con Charles François, el GESI y la nueva síntesis.</p>
            <h2>1. Regulación biológica en salud y consistencia sistémica</h2>
            <p>Para el mantenimiento de las variables requeridas en el proceso de vida, cuenta nuestro sistema orgánico con múltiples oportunidades y circunstancias de control y ajustes que tornen viable el mantenimiento con vida de cada cual en función de sus requerimientos circunstancial es. Entre otros conjuntos reguladores (redes) podemos señalar la instancia circulatoria gestionada, entre otros componentes por la Red de la que forman parte los Módulos de Keith y Flash, Ashof Tawara y la Red de Purkinge en el encuadre del momento cardio-circulatorio. Otro ejemplo lo constituyen los Centro Nerviosos como el Centro Respiratorio; entre otros.</p>
            <p>Podemos identificar efectores que comprometen al conjunto personal viviente, ejerciendo control y ajuste permanente en función de circunstancias y resultados. “Circunstancias”: “circum-stare”: aluden a la situación aquí y ahora, en particular a los requerimientos coyunturales emergentes.</p>
            <p>Controlamos y ajustamos las constantes orgánicas, como la temperatura y las funciones cardíaca y respiratoria entre otras. A la vista de requerimientos y resultados, estas constantes se orientan y ajustan de modo permanente, manteniendo el proceso vital en una franja de viabilidad en medio de un diálogo operativo consigo mismo y el medio.</p>
            <p>Salud: constituida en lo básico, por adaptación permanente a circunstancias y requerimientos (conducta plásticas). Cuando estudiamos y revisamos las constantes orgánicas lo hacemos en el encuadre de situaciones basales; vale decir sin impulsos ajenos a lo ordinario. La adaptación posibilita y determina, a la postre, la propia supervivencia.</p>
            <p>En oportunidades los estándares empobrecen y desactualizan los ajustes circunstanciales con los riesgos consiguientes. El componente cultural se transforma en oportunidades, en una amenaza más (¿cuánto peso? ¿qué tensión arterial tengo y con qué frecuencia cardíaca?). Un</p>
            <p>ejemplo lo constituyen las expectativas musculares, como la de su oxigenación suficiente frente a circunstancias cambiantes en oportunidades críticas (productoras de dis-estrés).</p>
            <p>Los músculos, en general,  cuentan con dos vías de oxigenación:</p>
                <ol>
                    <li>La habitual, mediante la incorporación, oportuna y suficiente, de oxígeno destinado a la homeostasis, a través de la acción respiratoria (inspiración enriquecedora, y expiración, expulsora de componentes dañinos y tóxicos). Hasta aquí, la oxigenación aeróbica o directa.</li>
                    <li>La oxigenación indirecta con etapa intermedia, como es la elaboración del ácido láctico en el seno del músculo mientras esperamos la llegada, al mismo” del oxígeno de origen externo .. Si el acceso del oxígeno se demora, el músculo “gritará” su falencia, mediante el dolor (espasmos musculares; angor pectoris). La transformación en ácido láctico facilita y toma “fisiológica” la espera: fenómeno habitual, en circunstancias de buceo y aumento de velocidad, en etapas finales del esfuerzo deportivo. Si la falta de oxígeno se prolonga en demasía surge el dolor: el músculo se “enferma”, y hasta puede morir, en parte (infarto).</li>
                    <li>Los factores intervinientes, que trascienden l estrictamente sanitario</li>
                </ol>
            <p>Al bucear entre los ingredientes condicionantes de la salud observamos, anonadados, que los impulsores trascienden la frágil membrana conceptual y la penetran. Para no exceder lo razonable en las determinaciones, constatamos que el encuadre cultural es el gran plasmador de aciertos y disvalías. Como factores determinantes, además de los estrictamente sanitarios, se integra el deber ser y, en particular, la educación, que tiñe y matiza las calificaciones. La alimentación y sus “folk ways” nos modelan e impregnan. También el medio, y las conductas humanas que nos afectan.</p>
            <p>El entorno y el obrar humano constituyen marcadores, secundados por modificaciones permanentes y, en apariencia, profundas, de la realidad eco lógica circundante que impulsamos, incluido el derroche y los impactos climáticos de riesgo ecológico, ausentes conductas globales autoprotectora y pro-organizadoras; todo lo cual tiñe el futuro de perplejidades y preguntas.</p>
            <p>La vivienda y su valor sanitario, agua potable y alcantarillado, las accesibilidades cronológicas, culturales y eco lógicas, incluídos ingresos económicos compatibles con la canasta familiar constituyen, con frecuencia, algunos de los desafio s e incógnitas, en relación con la salud posible, más allá del Sector y sus requerimientos los que, sin disminuir la importancia de lo sectorial, evidencian su predicamento.</p>
            <h2>2. La visión sistémica, y sus requerimientos</h2>
            <p>En este caso abordamos una realidad sistémica, la de la persona humana en sociedad, que opera como potencial sistema abierto. El conjunto no carga, entonces, con el desgaste y la inevitable entropía programada (sistemas cerrados), sino con su evolución, incluidas alternativas del proceso y características superadoras.</p>
            <p>Como sistema abierto ostenta la autorregulación como propiedad clave y la Cibernética (el arte de Navegar Entre Escila y Caribdis) como instrumento calificador de la naturaleza humana procesal (el “in fieri”). Los resultados y consecuencias podrían admitir la alternativa de estabilidad sostenida, cimentados en la retroalimentación y el control por resultados, con implementación de decisiones pro-organizadoras efectivas.</p>
            <p>El abordaje sistémico en salud tiene que ver con la vida y sus avatares. La vida, concebida como ajena a mezquindades y oportunismos, pero coherente con los sistemas abiertos y su complejidad. Pretendemos bucear en el orden subyacente bajo la apariencia del caos, con manifestaciones fisicas (corporales) informadas en la intimidad (“informadas”: penetradas por el alma, que las anima y les da forma). Alma y cuerpo no constituyen entidades separadas (somos víctimas de la manía analítica), sino plenamente interpenetradas.</p>
            <p>El ser humano y los antropoides ostentan, en el quehacer práctico, características procesales que los asemejan: fabricación de herramientas, adiestramiento en su manejo, capacitación endogrupal (como consta, en experiencias desarrolladas en el seno del Instituto Smitsoniano por R. Sheldrake). Comunicación e interacción son características de estos primates. Entre los primates como sistemas vivientes, según experiencias acumuladas, pueden desdoblarse o destruirse, o bien generar un nuevo orden.</p>
            <p>El nuevo orden reunirá condiciones adecuadas para superar las turbulencias.</p>
            <h2>3. Interacciones y contactos</h2>
            <p>Los seres vivientes y, en particular, los seres humanos, demuestran Capacidad de potenciación recíproca, mediante la intervención de redes e intercambios enriquecedores. La cooperación constituye una . forma genuina del interjuego vinculante, de vigencia y generación productiva. La influencia recíproca puede asumir resultados epidémicos positivos, como la generación y condicionamiento de conductas pro-organizadoras.</p>
            <h2>La visión sistémica y su aplicación sanitaria</h2>
            <p>La visión sistémica constituye una manera de pensar, en términos de conectividad, relaciones y contextos; diálogo vigente en la realidad del ser humano y su entorno.</p>
            <p>Cambia el enfoque, transfiriéndolo de las partes al todo. Siempre enferma todo el ser viviente; no alguna de sus partes, aisladas.</p>
            <p>Las propiedades, para encarar y superar los incidentes, son del conjunto integrado, más allá del abordaje concreto a un órgano.</p>
            <p>Las partes evidencian relaciones organizadoras referidas al conjunto; que pueden llegar a su exclusión, en caso necesario (vesícula biliar excluida).</p>
            <p>Cualquier abordaje de la realidad orgánica que la disecciona se orienta hacia la anulación y destrucción del sistema mismo (vivisección conceptual).</p>
            <p>Los sistemas mismos no pueden ser comprendidos desde el análisis, sino que exigen la síntesis revitalizadora.</p>
            <h3>Este artículo es parte de una presentación más extensa en una actividad internacional en la que el autor participó.</h3>
        </div>
    )
}