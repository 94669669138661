export const Article6 = () => {
    return (
        <div>
            <p>La globalización es en realidad un fenómeno histórico cuyas raíces se hunden en el pasado más lejano de la humanidad. La transformación a nivel planetario es sólo la fase última de una muy larga y significativa evolución. Por tal razón, es de gran interés retrotraerse a las fases anteriores del proceso con la intención de discernir mejor cuáles son las fuerzas que subyacen a esta transformación desde los obscuros milenios de la prehistoria.</p>
            <p>Están desapareciendo bajo nuestros ojos las últimas culturas arcaicas que pueden rendir testimonio acerca de las etapas anteriores de este proceso de complejificación progresiva de las socieidades humanas. Las sociedades arcaicas poseían características que, pese a las apariencias, siguen vigentes en las sociedades contemporáneas más avanzadas. Otras, que fueron adquiriendo en el transcurrir del tiempo, muestran y explican ampliamente la tendencia hacia la concentración en unidades cada vez más grandes y cada vez menos numerosas en cuya fase final nos hallamos en el presente, complicados.</p>
            <h2>La Ecología Humana y su Evolución hasta el Siglo 1</h2>
            <p>No es posible afirmar nada de forma tajante acerca de los primeros grupos humanos en la prehistoria. Aunque haya buenas razones para suponer que aparecieron en Africa y que desde este continente emigraron paulatinamente hacia Europa y Asia, las pruebas paleontológicas están aún en discusión. Sin embargo, el estudio de las sociedades más avanzadas de primates, o sea las sociedades de chimpancés tales como han sido observadas desde más de 30 años en la Reserva de NGombe en Tanzania por la etóloga inglesa Jane Goodall, permite llegar a dos conclusiones probablemente acertadas.</p>
            <p>En primer lugar los grupos humanos más arcaicos ya eran “grupos”, probablemente poco numerosos, pero en los cuales coexistían varias generaciones. Con ello se quiere expresar que existían ciertas interacciones de tipo social, tanto entre individuos de una misma generación como entre generaciones sucesivas, gracias a la longevidad de al menos algunos individuos. Dichas interacciones deben haber derivado de condiciones individuales de dominancia o de sumisión, como las observadas por el etólogo holandés Frans De Waal, nuevamente entre grupos de chimpancés, que se refirió a sus descubrimientos hablando por ejemplo del “chimpancé político”, después de observar alianzas jerarquizadas entre algunos individuos para asegurar su control sobre el grupo.</p>
            <p>Otros investigadores, en este caso Holandeses e Indonesios, estudiando los orang-utans (vocablo que significa “la gente de la selva”) llegaron también a la conclusión que tienen formas de organización social. En definitiva, nada sorprendente: la tendencia a formar sociedades se observa en todo el mundo animal y, en ciertas circunstancias inclusive al nivel de las amebas (Caso del Dictiostelium discoideum). Esta extraña ameba forma “sociedades”… bajo la presión de condiciones limitantes en el entorno. (ver apéndice).</p>
            <p>La segunda observación general relativa a las sociedades de antropoides es que también ellos son estrechamente dependientes de sus condiciones de entorno, aunque se ha podido observar la existencia de algunos comportamientos que casi podrían indicar una tendencia hacia muy embrionarias técnicas. Los chimpancés de NGombe, por ejemplo, usan ramitas para introducirlas en las termiteras y así extraer a las termitas, su plato apetecido. También parecen usar ocasionalmente ciertas plantas para purgarse.</p>
            <p>Las limitaciones que resultan de las condiciones del entorno y la casi inexistencia de técnicas ejercen un riguroso control demográfico sobre los varios grupos. Jane Goodall pudo también observar una epidemia que redujo drásticamente la población del grupo que observaba.</p>
            <p>Según todas las probabilidades, los grupos humanos más antiguos y arcaicos no deben haber sido muy diferentes en su organización social. En cuanto a su técnica, es claro que con las herramientas de madera y de piedra apenas tallada del paleolítico, no se podía extraer del entorno mucho más que raíces y cazar algunos animales de escaso tamaño.</p>
            <p>Este tipo de existencia podía aún observarse hasta cierto punto en Africa Central hace unos cuarenta años atrás, por ejemplo entre los Pigmeos Bambuti del Ituri en el Noreste del Congo (o Zaire). Aunque los Bambuti disponían ya de arcos pequeños y de flechas con puntas metálicas (que solían envenenar) y que, con estas armas tan poco poderosas, se animaban a cazar hasta elefantes, no habían podido llegar ni a la agricultura ni a la cría de ganado, aún menor.</p>
            <p>En cierto sentido, también el lenguaje hablado puede considerarse como una suerte de herramienta para la comunicación y debe haber jugado un rol muy importante en la cohesión y eficacia de los grupos arcaicos, por ejemplo en la transmisión de conocimientos y en la coordinación de actividades colectivas, como la caza. Ya existen en los animales superiores, y hasta en los insectos sociales, formas de comunicaciones muy anteriores al lenguaje hablado. La comunicación, de cualquier tipo que sea, es la condición socializadora por excelencia. Pero existen dos condiciones básicas para su eficacia. La primera es que sea modulada – y eventualmente ritualizada – para implicar comportamientos significativos. Y la segunda es que pueda transmitirse de generación en generación por imitación (también facilitada por la ritualización).</p>
            <p>La aparición del lenguaje en el hombre es un fenómeno que ha quedado sumido en un misterio que probablemente no sera jamás aclarado. Representa obviamente un prodigioso perfeccionamiento de la técnica de la comunicación y debe haber jugado un papel decisivo en la sobrevivencia de los grupos prehistróricos.</p>
            <p>Sin embargo, aún así, estos grupos permanecían estrechamente sometidos a las limitaciones ambientales. Otra observación africana lo ilustra muy claramente: el semi-nomadismo, que también se observaba en Africa Central hace menos de un medio siglo.</p>
            <p>En la selva ecuatorial, se daba el caso de ocupación cíclica y periódica de sitios en la forma siguiente. El grupo llegaba a un cierto lugar en la selva y ahí quemaba los árboles en una extensión de algunas hectáreas. Sólo dejaban algunos árboles grandes para mantener algo de sombra. Construían sus chozas y empezaban algún cultivo apenas extensivo. En la selva vecina, los hombres cazaban y las mujeres recolectaban frutos, plantas y raíces comestibles.</p>
            <p>Al cabo de pocos años (cuatro o cinco) la fertilidad de la tierra estaba muy reducida, la caza escaseaba y las plantas de cosecha tendían a desaparecer. Llegado el momento en que la existencia en el lugar se tornaba insostenible, reunían sus pocas pertenencias, quemaban las chozas que abandonaban y se iban a varios kilometros de distancia para reemprender el mismo tipo de actividades. Como varios grupos circulaban así lentamente en la selva, sus movimientos se limitaban recíprocamente y tendían a tornarse más o menos circulares. El resultado era que cada 20 o 25 años un mismo grupo volvía aproximadamente a ocupar el mismo territorio, regenerado por esta especie de barbecho a largo plazo.</p>
            <p>Ovbiamente, este tipo de actividades imponía estrictas limitaciones demográficas, que se cumplían de distintas maneras, tales como lactancia muy prolongada con efecto anti-conceptivo, o epidemias periódicas. Esta situación era prácticamente estable en el largo plazo y correspondía al nivel técnico muy bajo de estas poblaciones.</p>
            <p>La comparación con el tipo siguiente de sociedades(dejando a parte casos particulares como sociedades de pescadores costeros o isleños, o nómadas del desierto) permite esbozar una suerte de ecología evolutiva general de las sociedades humanas.</p>
            <p>Hace 5000 o 6000 años, en el Oriente Medio (Ur, Sumer, etc), en el Oeste de la Península Indica (Mohenjo Daro, Harrapa) y en China Central, nacen las primeras sociedades agrarias, caracterizadas por el uso de mejores herramientas. Es el paso del Paleolítico al Neolítico, y después al uso de bronce y finalmente del hierro. Otras técnicas valiosas aparecen, como el arte de la alfarería, que permite la conservación de distintas substancias alimenticias.</p>
            <p>Todo ello acompaña la estabilización de la verdadera agricultura y la domesticación de algunas especies animales que dio lugar a la ganadería. De esta manera, nuevos recursos pueden ser explotados y su permanente disponibilidad puede garantizarse mucho mejor.</p>
            <p>Todo ello redondea en un importante crecimiento demográfico y un aumento de la densidad de poblamiento por unidad de superficie, al menos donde las condiciones naturales hacen posible la aplicación práctica de los progresos técnicos realizados.</p>
            <p>La etapa siguiente, en una evolución que tiende a tornarse más o menos continua, es el desarrollo de la organización política basada en los centros urbanos. Un nuevo y considerable progreso consiste en el descubrimiento y el progresivo perfeccionamiento de la escritura. Un reciente y fascinante estudio de los orígenes y desarrollo de la escritura en el Medio Oriente (Denise Schmandt Besserat) muestra que fue ello un proceso muy lento y progresivo, que debutó por la confección de pequeñas representaciones unitarias en arcilla, de los objetos que se debían contar.</p>
            <p>Sin embargo, una vez que el sistema pasó a ser una verdadera escritura (cuneiforme o jeroglífica, o pictórica como en China), pasó tambien a cumplir con dos funciones nuevas fundamentales:</p>
            <ul>
                <li>la posibilidad de conservar información de una manera permanente, independiente de los individuos conocedores de la misma, tanto en el tiempo como en el espacio</li>
                <li>la posibilidad de crear verdaderos centros de gestión de los recursos y eventual planificación de su conservación y usos presentes y futuros.</li>
            </ul>
            <p>El cumplimiento práctico de estas metas originó la diferenciación de nuevos y distintos tipos de individuos en las sociedades, en particular:</p>
            <ul>
                <li>una nueva clase de administradores socio-económicos, que no tardaron en transformarse en una verdadera clase política.</li>
                <li>la aparición de una casta o cofradía de individuos instruídos en el uso de los signos y de sus interpretaciones.</li>
            </ul>
            <p>Este grupo no tardó en subdividirse entre burócratas y sacerdotes, los primeros como señores de los números y los segundos – en considerable expansión de los roles trradicionales del hechicero – como guardianes del conocimiento y de sus usos para la conservación del orden social.</p>
            <p>Del punto de vista de la globalización, este nuevo sistema permitió la aparición de los primeros estados organizados. Al principio, fueron especies de principados locales, pero bien pronto surgieron los primeros estados de gran extensión y – apenas unos siglos después – los primeros imperios.</p>
            <p>Estas nuevas formas de organización trajeron tres características ligadas entre sí y típicas de todos los fenómenos de globalización:</p>
            <ul>
                <li>el considerable desarrollo de los medios de comunicación, tanto a larga como a corta distancia.</li>
                <li>el reemplazo de muchas pequeñas organizaciones territoriales por otras mucho más grandes y mucho menos numerosas.</li>
                <li>las economías de escala, que permitieron un uso más eficiente de los recursos disponibles, una reducción de los desperdicios, la creación de reservas destinadas a compensar eventuales desastres, y una mejor coordinación de las distintas actividades en la dimensión temporal (Time binding)</li>
            </ul>
            <p>Nuevamente todo ello permitió una importante expansión demográfica. Se estima que en China, en la época de los Reinos Combatientes (siglos 4 y 3 antes de Cristo), la población de la China Central era ya de aproximadamente 60 millones de personas.</p>
            <p>Resulta claro que la capacidad de un entorno natural de soportar un cierto nivel de población humana estíligada estrechamente al nivel de desarrollo tecnológico de la misma. Ello diferencia absolutamente a la especie humana de las especies animales que, sin tecnologías, dependen simplemente en forma directa de la capacidad del entorno de sustentarlas.Es aún el caso de las hormigas, avispas y abejas, cuya tecnología existe, pero no evoluciona.</p>
            <p>La evolución tecnológica humana prosiguió a partir del Neolítico de una manera continua, aunque lenta durante al menos 30 siglos. Puede considerarse que el fenómeno empezó acelerarse a partir del Renacimiento. Fue cuando nuevas invenciones fundamentales se combinaron para iniciar lo que se ve ahora como el principio de la unificación planetaria.</p>
            <p>Una de estas invenciones fue la imprenta (Gutenberg), que permitió la multiplicación de los instrumentos portadores de conocimiento: Ya no era necesario el lento y pesado proceso de copiado de manuscritos, ni el tener una bolsa llena de ducados para comprarlos.</p>
            <p>La segunda fue el perfeccionamiento de los conocimientos astronómicos y marítimos (Enrique, el Navegante, en Sagres), que permitio a los navegantes aventurarse primero a lo largo de costas cada vez más lejanas, y finalmente lanzarse en pleno Océano. También se crearon nuevos tipos de barcos más grandes y más aptos para la navegación oceánica, un mejor instrumental de navegación (brújula, sextante y un poco más tarde, catalejos) y un arte cartográfico mucho más preciso (de los portulanes a la proyección de Mercator).</p>
            <p>Todo ello llevó al viaje de circumnavegación del Globo por Magallanes, que es en cierto sentido el primer paso hacia la globalización planetaria. Llevó asimismo al primer intento de planetarización – por cierto inconsciente y no voluntario – por el imperialismo, inicialmente de los Portugueses y Españoles, seguido ulteriormente por el de los Ingleses, Franceses y Holandeses. Las feroces rivalidades nacionales entre potencias europeas hasta el Siglo 20 y los movimientos nacionalistas en los países descolonizados a partir de 1800 disimulan en parte el aspecto quizás más significativo del movimiento de conquista a escala planetaria, o sea el hecho de que implica desde al menos el Siglo 17 la creciente interacción entre todas las poblaciones y todas las regiones y ecosistemas del planeta, visible por el intercambio intercontinental de plantas y especies animales, y por migraciones humanas masivas, voluntarias o no.</p>
            <p>El hecho más importante en este sentido es la aparición de una red cada vez más extensa y densa de comunicaciones marítimas, que prefigura las futuras redes de comunicaciones de otros tipos.</p>
            <h2>El rol de las revoluciones médicas e industriales de los Siglos 19 y 20</h2>
            <p>La evolución hacia la globalización se aceleró a partir del Siglo 19, cuando la investigación científica empezó a conjugarse estrechamente con la evolución tecnológica, por un efecto de resfuerzo mutuo. Dos aspectos de esta evolución merecen considerarse con más detenimiento, porque originaron en gran parte las transformaciones continuamente aceleradas que nos han llevado a la situación actual, con un considerable potencial de desastres, más también de decisivas mutaciones positivas.</p>
            <p>El primer aspecto es el extraordinario progreso de la ciencia médica cuyas consecuencias más significativas – ya no consideradas del punto de vista de los individuos, sino de las sociedades y quizás de la especie humana en su totalidad – pueden resumirse así:</p>
            <ul>
                <li>los progresos de la higiene y del arte de curar han alargado – de hecho, más que duplicado en menos de dos siglos – la esperanza de vida de los individuos que, por ejemplo, era apenas de 32 años en Europa en la época de Napoleón.</li>
                <li>se ha producido una explosión demográfica planetaria, aún en curso actualmente. Es así que la población del planeta ha pasado de 1.650 millones en 1920 a cerca de 6.000 millones en la actualidad.</li>
                <li>el fenómeno se traduce por una sucesión de cambios que no se habían previsto y frente a los cuales no se hizo ninguna planificación. Primero en los países occidentales en en vías de industrialización en Europa en el Siglo 19, y ulteriormente en todos los países del mundo, se produjo una explosión de la población joven (En la actualidad, por ejemplo, cerca de la mitad de la población argelina tiene menos de 25 años… pero carece de un buen nivel de instrucción)</li>
                <li>En una instancia ulterior, la pirámide de las edades tiende a invertirse: se observa una creciente denatalidad en Europa, en EE.UU.y en Japón, y la proporción de ancianos que no trabajan más pero necesitan recursos y atención social y médica empieza a crecer en forma acelerada.</li>
                <li>La explosión demográfica mundial, combinada con un crecimiento exponencial del uso de los recursos y la producción de desechos estítransformando a la especie humana en una verdadera plaga planetaria La revolución científica y tecnológica ahora permanente constituye el segundo factor de aceleración y del actual desequilibrio. El hecho puntual más importante ha sido el progreso en la explotación de los recursos energéticos fósiles a partir del Siglo 19. En efecto, el desarrollo económico tal como lo conocemos hubiese sido imposible al disponer sólo, como en el pasado, de la fuerza muscular del hombre y de algunos pocos animales: sin energía la explotación intensiva de cualquier recurso es imposible.</li>
            </ul>
            <p>La primera fuente de energía fósil fue el carbón, base de la industrialización del Siglo 19. El petróleo de uso industrial se remonta a la primera perforación del Coronel Drake en Titusville (EE.UU.) en 1859. Pero para el inicio del progreso exponencial hizo falta el invento del motor a combustión interna, precisamente en 1860 (Lenoir en Francia) y la rápida aparición de dispositivos complementarios que hicieron práctica la nueva invención.</p>
            <p>Este proceso – aún en pleno auge actualmente – ha dotado en menos de un siglo a la especie humana de una fuerza de transformación planetaria sin parangón, salvo en comparación con la mega-evolución geológica y paleontológica.</p>
            <p>Y, lamentablemente, no hubo hasta los años 1970 ninguna toma de conciencia de este hecho sin precedentes. Por lo tanto, no existe aún ahora ninguna planificación valedera que tenga en cuenta las probables consecuencias de la nueva situación. Aún, la incipiente reflexión sobre estos temas sigue fragmentaria, limitada e inconexa, en buena parte debido a la hiperespecialización reinante en las ciencias y las técnicas.</p>
            <h2>La revolución globalizante</h2>
            <p>Ahora estamos presenciando lo que será probablemente la última fase del proceso de planetarización de la especie humana. Según todas las apariencias el futuro nos pisa los talones y va ser mejor estar preparados para enfrentar los violentos coletazos que muy probablemente nos esperan.</p>
            <p>En otro momento consideraremos las modalidades de la globalización económica, ecológica, social, cultural y política. Por ahora nos limitaremos a considerar algunos nuevos factores que nos empujan, sin que la inmensa mayoría de la gente tenga la menor conciencia de las implicancias.</p>
            <p>Todos hablamos de la “revolución informática”. Pero las consecuencias globales posibles o probables de la misma distan aún mucho de ser claras. Como siempre, la preocupación de “hacer dinero” con la computación, y ahora con el Internet es la primera que surge. Pero ello es un punto de vista muy miope.</p>
            <p>Si uno recuerda la corta historia de la informática, se observan algunos aspectos muy significativos. En primer lugar, se impone la evidencia de la extraordinaria velocidad de la potenciación de la nueva técnica. Hace apenas 50 años que Alan Turing y John von Neumann establecieron los principios mismos del cálculo digital por máquinas electrónicas. Las primeras computadoras eran monstruos tan enormes como frágiles, cuyo hardware necesitaba la atención permanente de un ejército de técnicos. Sin embargo, su poder era muy inferior al de cualquier computadora personal actual. Nadie en esa época vislumbraba las transformaciones por ocurrir. El temor de los que reflexionaban sobre el futuro era que la humanidad entera iba a caer bajo el control de alguna pequeña elite de tecnócratas que la manejaría a su antojo y, por supuesto, en provecho propio. En cierto sentido – pero solamente en cierto sentido – ello era exactamente lo opuesto a lo que pasa en realidad, ahora que el “poder informático” estímundialmente distribuído entre decenas, y quizás centenares de millones de personas.</p>
            <p>Esta distribución descentralizadora ya sería significativa en sí. Pero la aparición y el fulminante desarrollo del Internet le otorga una nueva dimensión. La computadora retoma ahora con una prodigiosa amplificación el rol de la palabra, primero escrita y después impresa, en la creación de una gigantesca red de comunicación y de transmisión de la información. El Internet se constituye rápidamente en el sistema nervioso – y quizás algo como el neo-cortex cerebral – de la humanidad planetaria. Pero resta ahora por ver lo que transmitiríeste sistema nervioso: Servirá para difundir una nueva versión del “Pan y circo” (cuando su inminente fusión con la televisión se cumpla)? Servirá para nuevas formas de la explotación humana? Servirá para la creación de una supercultura planetaria? Servirá para la instauración de una especie de auto-gobierno planetario colectivo – sensato o insensato – al cual todos tendrán acceso? Servirá para una masiva transformación existencial de la cual tenemos tan poca idea como nuestros bisabuelos podían tener de sus ciudades libradas al universal coche?</p>
            <p>Sea como sea, de ahora en adelante dependeremos todos de todos de una manera absolutamente sin precedentes. Mientras tanto, otros efectos globalizantes se perfilan en nuestro horizonte. Uno es la problemática de la disponibilidad y utilización futura en breve plazo de los recursos naturales en materias primas y en energía. La demografía planetaria todavía galopante, nos preanuncia una población global de 10 a 12 millones de individuos para el año 2050. Además nuestra red televisiva se estíencargando de mostrar al mundo entero un modo de vida que fue posible alrededor del Atlántico Norte únicamente por la movilización y consumo irreversible de recursos a escala de todo el planeta. Como ello se hizo y se hace en el probable detrimento de los “have not” presentes y de las generaciones futuras, las situaciones que preanuncia parecen más bien cargadas de problemas.</p>
            <p>Otro efecto inquietante es la globalización de la basurización a escala planetaria. Un reciente relevo de los desperdicios traídos por las olas y encontrados en las playas de una pequeña isla desierta del Pacífico Central deja el lector entre la risa y la furia. La especie humana parece ser la única que encuentra normal ensuciar su propio nido: los individuos no parecen darse cuenta que el “not in my backyard” personal no resuelve el problema. Y, lamentablemente, hasta la capacidad del planeta para digerir y asimilar nuestros desperdicios tiene límites… globales.</p>
            <p>Las consecuencias globales de la contaminación de los suelos y de las aguas, de la desertificación de los suelos por los abusos que se hacen de su fertilidad, de la destrucción masiva de la fauna y de la flora (en particular las selvas),de las paulatinas pero acumulativas alteraciones de la atmósfera, etc.. son tantas facturas que el planeta se prepara a presentarnos… globalmente… y a más o menos corto plazo.</p>
            <p>Mientras tanto el genio tecnológico, salido de la lámpara del imprudente Aladino, sigue en plena actividad. Seguimos viviendo más o menos bajo la amenaza nuclear. Pero ahora, por ejemplo, se nos promete la amenaza bacteriológica.Y sin llegar a extremos tan malévolos, seguimos con nuestra costumbre de inventar novedades potencialmente poderosas sin tener la menor idea de sus posibles consecuencias imprevistas generalmente materializadas a corto plazo por la codicia o la irresponsabilidad de algunos. Ya se escuchan por ejemplo horrorosas historias de tráfico de órganos humanos. Uno se pregunta en qué medida podrán manejarse prudentemente en el futuro los usos de nuevas técnicas biológicas (como la clonación) y electrónicas (como la incipiente “vida artificial”). Los ejemplos recientes de los antibióticos e insecticidas son aleccionadores, pero poco tranquilizadores.</p>
            <p>Lo anterior no debe confundirse con las tradicionales jeremíadas anti-progreso, que nos mentendrían aún en las cavernas, de haber sido escuchadas en el pasado. Simplemente se quiere destacar que, con la multiplicación de nuestros poderes y muy en especial la inminente construcción del mundo global donde todo y todos tendrán que ver con todo y todos, es urgente que hagamos un considerable esfuerzo de reflexión y que tratemos de aumentar nuestro control… sobre nosotros mismos, individualmente y colectivamente.</p>
            <p>Como lo propone el Inglés Gordon Dyer, de la Open University, es gran tiempo que dupliquemos la Carta de los “Derechos del Hombre” (y de la Mujer, y del Niño) por otra: la Carta de las “Responsabilidades del hombre” (por qué no quitarle en este caso la majestuosa mayúscula). Y no sólo hacia los miembros de su propia especie, sino hacia su morada planetaria y los otros habitantes de la misma, sin los cuales a fin de cuentas no podrá sobrevivir.</p>
            <p>El contenido de esta proyectada Carta podría útilmente inspirarse de los principios cibernéticos relativos a las regulaciones positivas y negativas y a la necesidad de armonización entre las partes y los procesos que las hacen co-funcionales.</p>
            <h2>BIBLIOGRAFIA</h2>
            <p>DYER, Gordon: “A Bill of Rights and Responsabilities” (Comunicación a las 9as Conversaciones de Fuschl, Abril 1998)</p>
            <p>FORUM INFO 2000: The “Stuttgart Charta” Informationsgesellschaft und Nachhaltige Entwicklung – Information Society Forum, Brussels, July 2nd, 1998</p>
            <p>FRANÇOIS, Charles: Enfoques Sistémicos en el Estudio de las Sociedades – GESI-AATGSC, Buenos Aires, 1986</p>
            <p>GOODALL, Jane: The Chimpanzees of Ngombe: Patterns of Behavior, Belknap Press, Harvard Univ. Press, Cambridge, Mass, 1986</p>
            <p>HABERMAS, Jürgen: Technik und Wissenschaft als Ideologie – Suhrkamp Verlag, Frankfurt am Main, 1968</p>
            <p>RADERMACHER, F.J.: Population Development, Globalization and Environment: Crisis Points – Inaugural adress to the 14th European Meeting on Cybernetics and Systems, Vienna, April 1998</p>
            <p>SCHMANDT – BESSERAT, Denise: Onenes, Twoness, Threeness: How Ancient Accountants Invented Numbers – New York Academy of Sciences, 1997</p>
            <p>WAAL, Frans de: Good Natured: The Origins of Right and Wrong in Humans and other Animals – Harvard Univ. Press, Cambridge, Mass, 1996</p>
            <p>WRANGHAM, Ricahrd W. & al.(Ed): Chimpanzee Cultures – Harvard Univ. Press, Cambridge, Mass, 1994</p>
        </div>
    )
}