export const Article8 = () => {
    return (
        <div>
            <p className={"align-center"}>Asociación Argentina de Teoría General de Sistemas y Cibernética</p>
            <h2>Una falacia fundamental de las teorías económicas</h2>
            <p>Bajo el rubro muy conocido de “externalidades”, las teorías económicas apartan generalmente de sus preocupaciones lo que, en realidad, es el fundamento absolutamente imprescindible de toda actividad humana. Se trata del substrato energético y material global que nos ofrece la naturaleza. Ello incluye en primer lugar la energía solar y todas las energías derivadas de la misma, en particular las diversas formas de energías fósiles, que representan un capital acumulado durante largas eras geológicas, que la humanidad estígastando desde 150 años a un ritmo constantemente acelerado, sin relación alguna con las posibilidades de renovación natural.</p>
            <p>Pero las omisiones ne se limitan a este aspecto básico. Se extienden también a las siguientes formas de despilfarro sin compensaciones:</p>
            <ul>
                <li>recursos minerales de alta concentración</li>
                <li>riquezas forestales y marítimas explotadas de una manera generalmente destructiva</li>
                <li>biodiversidad animal y vegetal, muchas veces destruída antes de cualquier estudio en cuanto a su posible utilidad, potencial necesidad y posibilidades de reemplazo</li>
                <li>elementos básicos de sustentación permanente de la estabilidad y quizás de la sobrevivencia, de parte o totalidad de la vida de las comunidades biológicas de que somos partes integrantes: aguas dulces no contaminadas, suelos cultivables, atmósfera equilibrada</li>
                <li>sobrecarga creciente de los sumideros donde descargamos los subproductos inutilizables de las numerosas actividades humanas.</li>
            </ul>
            <p>Se postula – generalmente de manera implícita, lo que quiere en realidad decir “sin haberlo siquiera pensado” – que todo ello seguiríestando siempre disponible, pese a la universal aceleración del ritmo de consumo.</p>
            <p>Todo lo anterior tiene además un denominador común: Tanto el consumo y la degradación masiva de energía, como la disminución general del orden ecológico global, traducen un aumento de la entropía global del sistema humanidad – planeta, posiblemente reflejada en las violentas crisis de anarquía local o más general que empezamos a presenciar.</p>
            <p>En realidad, tanto el sol como el planeta subsidian constantemente las actividades humanas. El implícito postulado que estas “externalidades” pueden darse como eternas y sin compensación aparecerítarde o temprano como insostenible. Ya estan vislumbrándose los primeros mega-problemas como, por ejemplo, los posibles cambios climáticos globales generados por el cada vez más probable calentamiento de la atmósfera (un típico fenómeno termodinámico de degradación masiva de las energías nobles en calor). Parece probable que otros problemas del mismo tipo no tarden en aparecer.</p>
            <h2>Otra falacia conceptual que nos aqueja es la ignorancia del factor tiempo.</h2>
            <p>Por ejemplo, tanto la pesca oceánica como la explotación forestal serían sustentables si diéramos el tiempo a los cardumenes de peces y a los bosques para regenerarse. Pero, precisamente, nuestros modos de explotación – que en realidad deberían definirse como saqueo – son tan destructivos que, en muchos casos la regeneración se torna imposible, aún a largo plazo, porque las condiciones básicas de la misma han sido vulneradas de manera decisiva.</p>
            <p>Terminamos con entornos ecológicos degradados de manera irreversible, o por lo menos reversible sólo a escala de siglos o de milenios. Ello es, a escala planetaria la “Tragedia de los bienes comunes” descrita hace cerca de 50 años por Garett Hardin.</p>
            <p>Lo que pertenece “a todo el mundo” pertenece en realidad a unos pocos que se encuentran en condiciones de saquear estos bienes comunes en su propia ventaja (de corto plazo, ya que en definitiva vendrá la hora de la verdad en que se reconocerá que habrá cada vez más perjudicados y cada vez menos posibilidad de escapar a las consecuencias).</p>
            <p>Ello no se percibe hasta ahora porque la aceleración exponencial de los procesos de explotación no ha llegado aún a poner a la humanidad en un callejón sin salida.</p>
            <h2>Necesidad de un Nuevo Enfoque en la Administración de la Economía Global</h2>
            <p>El saqueo en curso parece deber llevar inevitablemente a la ruina global y universal en un plazo cada vez más corto. Es el resultado de un enfoque general corto de vista, basado en el convencimiento que si no se quiere ser perdedor, hay que pelearse por todos los medios disponibles – lícitos o no – para ser ganador en este juego del saqueo universal. En realidad, ello equivale a serruchar la rama donde estamos todos sentados. Cuando vendrá la hora de la verdad, se verá probablemente que sólo habrá vencidos.</p>
            <p>En el fondo, se trata de una falla mental, que se ve reflejada en muchas otras maneras de actuar. Todos dependemos en última instancia de un mismo substrato ecológico. Y nadie puede vivir aislado dentro de la sociedad. Tampoco ningún grupo humano podríseguir despilfarrando eternamente el capital planetario, confundiéndolo (a veces a sabiendas) con las utilidades que produce.</p>
            <p>Por supuesto, muchos de los saqueadores y de los perjudicados van a desaparecer de la escena de la vida antes de que las consecuencias de sus actividades se hagan visibles. Quizás sea ello un reflejo de una especie de desesperación existencial muy característica de nuestra época de valores y normas en ruinas. Ello equivale a dejar un regalo envenenado a nuestros descendientes, o sea que, en definitiva, toda la cuestión tiene un trasfondo ético, poco visible en una época que se preocupa masivamente por los derechos y muy poco por las responsabilidades que son necesariamente garantes de la posibilidad de ejercer estos derechos en una sociedad medianamente organizada y estable.</p>
            <p>Uno de los primeros pasos que deberíamos dar en dirección de actitudes más satisfactorias sería establecer nuevos modelos de lo que podría llamarse economía ecológica.Una idea que ha empezado a circular discretamente en diversos ambientes políticos y económicos es la necesidad de internalizar el entorno en nuestros modelos.</p>
            <p>Por ejemplo, la explotación de un bosque tiene un aspecto positivo por la producción de riquezas utilizables para nuestros fines económicos de producción, consumo, bienestar, etc… Pero al mismo tiempo, el bosque arrasado – o simplemente reducido a un sotobosque sin valor explotable actual – representa la compensación negativa (aún en el simple sentido contable) de la riqueza explotada.</p>
            <p>En el caso del bosque, o de la pesca marítima, por ejemplo, es posible que la riqueza se reconstituya, a condición que la explotación no haya sido totalmente destructiva. Pero aún en este caso, se necesitaríun tiempo prolongado para esta reconstitución. En el caso de los bosques, este tiempo se cifrará entre 20 y 100 años según los tipos de árboles, el clima local y el uso que se le daríal entorno durante este período.</p>
            <p>Esta demora es el reflejo de la inevitabilidad termodinámica de la obtención de energía para la reconstitución del recurso de alto nivel de organización propia.</p>
            <p>Lo mismo vale si se considera que el reciclaje de recursos no-renovables, por ejemplo minerales, exigirá también recursos energéticos, lo que finalmente podría ser el factor limitativo último, cuando mermarán finalmente las disponibilidades de energías no-renovables. Ello también deberá intervenir en la contabilidad global de los recursos planetarios.</p>
            <p>Se vislumbran de esta forma las posibilidades – pero también las dificultades- de establecer una verdadera contabilidad patrimonial, local, regional, nacional o planetaria. Las dificultades de tipo técnico son por lo menos dos:</p>
            <ul>
                <li>Cada caso es específico: las condiciones de reconstitución de las existencias explotables de merluza difieren de las relativas a la anchoveta peruana. O el bosque patagónico tiene un tiempo de reconstitución diferente del bosque de zonas templadas cálidas.</li>
                <li>Pueden existir factores que influenciarán indirectamente la recuperación. Podría acelerarla por medio de intervención humana (pero a un costo que habría que evaluar).O bien factores evolutivos, como por ejemplo la aparición de una especie competitiva, o de una variación climática, pueden acelerar o ralentar esta recuperación. En síntesis, harán falta muchos estudios para llegar a una contabilidad patrimonial más o menos incontrovertible.</li>
            </ul>
            <p>Por ahora, este tipo de reflexiones interesa apenas a algunos investigadores que tienen una influencia muy limitada sobre el mundo empresarial y político, siempre preocupado por resultados a corto plazo. Las luchas de influencias en las conferencias internacionales sobre la protección de la atmósfera muestran claramente que los intereses económicos adquiridos y dominantes, y los factores de oportunismo político dominan la escena.</p>
            <p>Pero esta situación evolucionaría medida que los problemas aparecerán tornándose más agudos. Hace apenas 50 años, no se habia siquiera tomado prácticamente en ninguna parte la conciencia de que podrian existir problemas de esta naturaleza.</p>
            <p>Por ahora, lo importante es tomar conciencia de la situación que se nos presenta en todas las escalas geográficas y de tiempo, y trabajar por un lado en difundir esta conciencia, y por otro en producir modelos conceptuales y operativos más precisos y manejables.</p>
        </div>
    )
}