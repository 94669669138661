export const Article4 = () => {
    return (
        <div>
            <p>La globalización es un fenómeno asociativo que implica un número considerable de interconexiones nuevas entre múltiples elementos en un nivel superior de complejidad. Provoca la aparición de formas sociales originales que introducen características de conjunto anteriormente inexistentes.</p>
            <p>Por ahora, nos referimos a la “globalización” como un fenómeno que afecta a la humanidad en el nivel planetario. Pero, en cuanto a manifestación asociativa en sí misma, no es más que una nueva instancia de una transformación evolutiva que se ha producido numerosas veces en la historia humana (aunque a escala mucho más limitada). Corresponde también a un proceso que ha afectado y sigue afectando numerosos grupos de seres vivos desde las amebas hasta los insectos sociales.</p>
            <p>Parece, por lo tanto, posible establecer un modelo general sistémico del fenómeno asociativo. Tal modelo nos podría ser muy útil para comprender mejor lo que está ocurriendo y las virtualidades aún difíciles de vislumbrar que implica o podría implicar. Además podría darnos herramientas conceptuales y psico-sociales para prepararnos a cambios considerables que, sin duda, se avecinan.</p>
            <h2>Descripción del fenómeno asociativo</h2>
            <p>El fenómeno tiene características generales que se observan en todas las situaciones en las que se manifiesta. Empieza por una multiplicación masiva de un cierto tipo de individuos pertenecientes a una especie animal. Ello se hace posible cuando la especie encuentra un medio ecológico favorable, o un nicho adecuado en un medio de este tipo. Esta multiplicación sigue una regla malthusiana: prosigue sin cesar hasta que los límites del medio o del nicho ecológico sean rebasadas. Una situación extrema de esta clase puede producir fundamentalmente dos resultados diferentes. El primero es el caso general más frecuente. El segundo es muy excepcional.</p>
            <p>En caso de supersaturación del medio por la especie, se observa normalmente un derrumbe de la excesiva población alcanzada. Ello puede resultar de hambrunas, pandemias o, alternativamente de fenómenos de masas de carácter psicosocial. Es bien conocido el caso del lemming, roedor de Escandinavia que periódicamente se beneficia de condiciones favorables de entorno y se multiplica exponencialmente. Cuando el número de animales ya es tal que no alcanza más la fuente de alimentos, se congregan en grandes masas y se lanzan hacia el Oeste en una migración acelerada. Al cabo de algunos meses agotan sus medios de subsistencia en todo el territorio y mueren casi todos. El fenómeno es más o menos cíclico y se repite al cabo de algunos años a partir de unos pocos individuos sobrevivientes.</p>
            <p>Una situación similar origina las grandes migraciones de langostas en Africa Central. Estas terminan finalmente al cabo de varios años hundiéndose en masa en el Mediterráneo Oriental, en el Mar Rojo o en el Océano Indico, o perdiéndose en el Sahara.</p>
            <p>En todos estos casos, parece que existe una masa crítica más allá de la cual el comportamiento de los individuos se modifica, pasando de una fase solitaria (o al menos autónoma) a una fase gregaria. La masa crítica está relacionada, según se cree, con el grado de hacinamiento creciente que provoca una considerable reducción del espacio disponible para cada individuo. Ello, posiblememnte asociado con un cierto debilitamiento fisiológico, cambia las percepciones que los individuos tienen de sus congéneres y parece generar un comportamiento colectivo nuevo, caracterizado por una suerte de polarización uniformizante. Se supone que ello se produce por la percepción recíproca incrementada de olores o de contactos visuales u otros, que generan una suerte de atmósfera propia de las muchedumbres.</p>
            <p>Se conoce sin embargo una variante significativa en las reacciones de una especie de ameba, donde la supersaturación del medio y el hacinamiento provoca un fenómeno de socialización emergente de gran interés. Esta ameba, Dictyostelium discoideum, se reproduce por esporas que aparecen cuando termina el ciclo que se describe a continuación. Algunas amebas individuales nacen de las esporas cuando las condiciones de entorno son favorables (humedad, alimentos). Las amebas empiezan a multiplicarse por división y así aparecen numerosos individuos independientes.</p>
            <p>Sin embargo, cuando la población llega a una cierta densidad crítica en relación con las condiciones ambientales las amebas dejan de multiplicarse, probablemente por un efecto de masa producido por la densidad de interacciones. Algunas amebas se tornan centros de concentración bajo la influencia de un agente bioquímico, la acrasina (adenosina monofosfática cíclica – cAMP). Se trata de un proceso conocido como quimiotaxis. Al cabo de un cierto tiempo, los distintos grupos se fusionan en una masa única. Si las condiciones adversas persisten, esta masa se diferencia, formando un especie de hongo con un pie, un pedúnculo y en la cima de éste una pequeña esfera donde se forman nuevas esporas. El conjunto se deseca y las esporas se dispersan en el entorno, a la espera de condiciones nuevamente favorables.</p>
            <p>Este curioso proceso es obviamente una forma elemental de socialización emergente, favorable a la sobrevivencia de la especie. Es notable que se pueda observar en un ser viviente tan simple. El fenómeno se conoce desde mucho tiempo, pero Prigogine y su alumna Anne Babloyantz lo han descripto en términos de estructuración disipativa. Ello nos ayudaría a comprender mejor la naturaleza de la socialización en la especie humana.</p>
            <p>Un paso suplementario en la dirección de una socialización más compleja aparece con las esponjas. Lo que conocemos como tal es de hecho una construcción social residual edificada por numerosos pequeños organismos agrupados en colonias. Ello es aparentemente el ejemplo más sencillo de una exoestructura permanente de origen social. Tiene obviamente una función de protección recíproca, que volveremos a encontrar en otras situaciones sociales.</p>
            <p>Si consideramos ahora animales más evolucionados, observamos dos tipos de comportamientos distintos, los que finalmente se fusionan en la especie humana. Muchas especies animales son sociales en cierta medida. Un ejemplo es el de los cardúmenes de peces. Estos animales nadan en grupos de miles de individuos en apariencia para protegerse mutuamente en la medida de lo posible contra los predadores. Al parecer tienen una percepción mutua de naturaleza visual y se agrupan de manera compacta, con unos cuantos individuos en la periferia del grupo y los otros dentro de la masa. Los individuos periféricos corren más peligro que los demás, ya que no están protegidos del lado exterior del cardumen. Pero rotan periódicamente con los otros, como si algún factor que hasta ahora no se ha descubierto generara una suerte de solidaridad social distributiva. Fenómenos parecidos se manifiestan en muchas aves migratorias, como se puede observar, por ejemplo, en los vuelos de patos o gansos. Es divertido saber que los corredores ciclistas suelen adoptar formaciones colectivas parecidas para luchar contra vientos frontales o laterales.</p>
            <p>Se ve a las claras que los comportamientos sociales favorecen al menos estadísticamente a los individuos que los adoptan. Este mutualismo parece ser una explicación realmente fundamental del hecho social. Con los insectos sociales, franqueamos otro paso trascendental. Termitas, hormigas y abejas viven en grandes sociedades, que suelen agrupar decenas de miles de individuos y tienen dos características nuevas de mucho interés. La primera es su verdadera organización social con especializaciones: Existe una “reina”, productora masiva y exclusiva de nuevos individuos, y grupos diferenciados como “obreras”, “soldados” y recolectoras. En el caso de las abejas, cada individuo llega a ejercer sucesivamente estas distintas funciones en correspondencia con su edad.</p>
            <p>La segunda característica es que estos insectos son constructores: edifican moradas colectivas que alcanzan una gran complejidad y constituyen una protección colectiva permanente que les asegura un “intorno” estable y duradero, por ejemplo con regulación muy precisa de la temperatura y del grado de humedad.</p>
            <p>La construcción de las termiteras, colmenas y hormigueros es en sí un fenómeno fascinante por el grado de coordinación y colaboración que exige de parte de diminutos animales con cerebros microscópicos. Esta coordinación, además, supone en muchos casos una cierta capacidad de aprendizaje y un grado de coordinación en el tiempo. El entomólogo francés Pierre Paul Grassé nos ha dejado descripciones extraordinarias de la edificación de una termitera y del proceso de coordinación recíproca. Lo ha llamado stigmergia y supone una notable capacidad de percepción y de interpretación entre estos insectos. Este tipo de fenómenos sociales marca un salto cualitativo en el nivel de socialidad y preanuncia la socialidad humana.</p>
            <p>Del punto de vista de la modelización sistémica podemos llegar a algunas primeras conclusiones generales. Los sistemas sociales son entidades complejas que emergen cuando se dan las condiciones elementales siguientes:</p>
                <ul>
                    <li>debe coexistir un número considerable de individuos de un mismo tipo.</li>
                    <li>esta coexistencia debe darse en un ambiente limitado</li>
                    <li>debe darse una expansión excesiva de la masa de individuos, que lleva a un cierto grado de concentración crítica en este ambiente y por lo tanto a alguna situación límite</li>
                    <li>Esta concentración aumenta las interacciones entre los individuos y modifica sus comportamientos.</li>
                    <li>Algunas de las interacciones que aparecen reflejan una tendencia a la protección recíproca de los participantes contra peligros exteriores. Ello se da por medio de ciertos modos específicos de ocupación y organización del espacio común</li>
                    <li>Las nuevas interaccciones no pueden ser incompatibles entre sí</li>
                    <li>Este tipo de situaciones debe tornarse permanente para llevar a la formación de la nueva entidad emergente (Sobre este punto, una teoría general de la autogénesis de los sistemas ha sido elaborada por el húngaro Vilmos Csanyi)</li>
                    <li>La emergencia de la situación social otorga características enteramente nuevas, propias de la entidad de conjunto.</li>
                    <li>Estas nuevas características dan una considerable ventaja a este tipo de entidad y a los participantes individuales – a costa, es cierto, de considerables restricciones a su autonomía.</li>
                    <li>En efecto, se ven obligados a cambiar muchas de sus pautas individuales de comportamiento. Ello equivale a decir que deben atravesar un aprendizaje de la socialidad.</li>
                    <li>También quedan sometidos a leyes de conjunto que modificarán o suprimirán algunos de sus comportamientos originales.</li>
                </ul>
            <p>Las entidades sociales se auto-organizan y conocen una fase de crecimiento más o menos prolongada. Una vez llegadas a la madurez, perduran por un período generalmente muy largo en comparación con los elementos individuales que las constituyen. Llegadas a madurez, las entidades sociales empiezan a auto-reproducirse, o sea son autopoiéticas, es decir reproducen constantemente sus estructuras y funciones características y son capaces de crear continuamente nuevos individuos dotados de las mismas características para reemplazar los componentes que desaparecen.</p>
            <h2>Causas y modalidades del fenómeno asociativo</h2>
            <p>La regla de eficacia de Herbert SIMON</p>
            <p>La tendencia a la socialización se impone por razones de eficacia, o sea de uso más eficiente de los recursos disponibles, incluído el tiempo. Simon lo explicó mediante la pequeña fábula relativa a los relojeros Hora y Tempus, ambos artesanos de gran habilidad y fabricantes de relojes muy apreciados, lo que les valía frecuentes pedidos por teléfono. Cada reloj constaba de unas 1000 pequeñas piezas. Tempus construía sus relojes pieza por pieza hasta completar un reloj por vez. A cada llamado telefónico el reloj en construcción se deshacía y tenía que reemprenderse la tarea desde el inicio. Como consecuencia, raras veces podía Tempus llegar a terminar un reloj. Hora por su parte había imaginado ensamblar un número de piezas en subpartes terminadas. Cuando se interrumpía, sólo perdía una pequeña parte de su trabajo. De esta manera llegaba a terminar muchos más relojes que Tempus, en el mismo lapso. El resultado fue que Tempus quebró y Hora prosperó.</p>
            <p>Pueden extraerse las conclusiones siguientes:</p>
                <ul>
                    <li>la complejidad resulta de la agregación ordenada de elementos</li>
                    <li>La complejidad se obtiene más fácilmente cuando la agregación se hace en niveles sucesivos.</li>
                </ul>
            <p>Se puede además emitir una hipótesis: La evolución, que quizás pueda considerarse como un mecanismo natural para el uso eficaz de los recursos por sistemas autónomos activos, procede probablemente por agregación progresiva más compleja de nivel en nivel, con emergencia de posibilidades nuevas en cada nivel.</p>
            <p>En este mecanismo pueden distinguirse tres características complementarias: las tendencias a la división del trabajo, a la especialización y a la aparición de meta-controles. Todas llevan a economías de escala, pero son posibles sólo en el marco de procesos complejos.</p>
            <h2>Las estructuras disipativas de energía</h2>
            <p>Los sistemas vivientes y meta-vivientes pueden existir en estabilidad dinámica o, al contrario llegar en algún momento a una situación de inestabilidad. La estabilidad dinámica se caracteriza por fluctuaciones adaptativas no muy amplias alrededor de un estado mediano, que tiende a mantenerse en el tiempo. Es por ejemplo el caso de cualquier ser viviente en condiciones normales de entorno, o de comunidades clánicas o rurales tradicionales.</p>
            <p>Prigogine logró una interesante formulación general de la estabilidad dinámica en términos termodinámicos, enunciando el “Teorema de producción mínima de entropia” según cual un sistema en un estado cerca del equilibrio y en un entorno suficientemente estable tiende al uso mínimo de energía compatible con su funcionamiento normal (y por lo tanto a la producción mínima de entropía correspondiente).</p>
            <p>Ya en 1924, Lotka había formulado la idea de que todos los sistemas naturales tendian a organizarse en el planeta para el aprovechamiento global máximo de la energía disponible, ello es referiéndose a la energía solar. Lotka veía la evolución como la aparición y la progresiva selección subsiguiente de los mecanismos más eficaces en este sentido, y llamó “World Engine” al gran conjunto en múltiples niveles de estos sistemas naturales.</p>
            <p>Sin embargo, el caso de las sociedades humanas introduce un factor nuevo. El hombre – dotado de un cerebro muy desarrollado, de una gran longevidad , de manos muy articuladas y de la aptitud linguística – es el primer animal que ha logrado inventar nuevas maneras de usar los recursos que la naturaleza puso a su alcance. Las hormigas y las abejas usan técnicas de explotación de algunos recursos naturales. Las primeras organizan cultivos de hongos que les sirven de alimentos y las segundas aprovechan el polen. Pero estas técnicas son inmutables.</p>
            <p>Por el contrario, el hombre nunca paró de perfeccionar sus técnicas e, inclusive, su evolución técnica no hizo más que acelerarse sin pausa. Una de las consecuencias más cruciales fue su capacidad de explotar fuentes de energía: las de los animales y las plantas, la hidráulica, la del viento.</p>
            <p>Dio un paso decisivo en los Siglos 19 y 20, cuando aprendió a usar masivamente las energías fósiles: primero el carbón y despues el petróleo y el gas natural. El consumo, por persona, de energía ha crecido en forma exponencial en los últimos 150 años. Ese fenómeno nos ha llevado a las formidables transformaciones que recubren las palabras “desarrollo” y “progreso”. Pero corresponden de hecho al fin de la estabilidad dinámica de las sociedades humanas y las ha llevado a situaciones de inestabilidad, lejos del equilibrio. Veamos dos ejemplos de los profundos cambios que resultaron del hecho:</p>
                <ul>
                    <li>la aparición del coche: En un siglo este nuevo artefacto surgido de la inventividad humana ha llevado a múltiples innovaciones que, por una razón que se explica a continuación, calificaremos de estructurantes. La construcción de los vehículos se ha tornado en una gigantesca industria mundial. Para la circulación de los coches, se ha cubierto el planeta de una red cada vez más extensa y densa de caminos. También se ha necesitado un complejo sistema de extracción, refinamiento, transporte y distribución del carburante. Se debió organizar la circulación de decenas de millones de estos nuevos vehículos, lo que exigió la invención y la instalación de una amplia red de señalización. Pronto aparecieron problemas de todo tipo que justificaron la creación de un código de circulación y de una policía caminera para hacerlo respetar. La reducción del espacio disponible llevó a la organización del estacionamiento y la seguridad a la creación de seguros contra accidentes y al control técnico. Todo ello generó la necesidad de muchas nuevas empresas e instituciones administrativas y judiciales: otorgamiento de registros de conductor, tribunales de faltas, etc…</li>
                    <li>la aparición del avión ha generado a su vez impresionantes estructuras: Grandes empresas de construcción aeronáutica; una red mundial de aeropuertos e instalaciones subsidiarias; sistemas de control del tránsito aéreo; grandes compañías de aviación y agencias de viajes, legislación sobre el tráfico aéreo, etc., etc…</li>
                </ul>
            <p>Nuevamente Prigogine nos ha dado la llave explicativa de estos fenómenos (a partir de la generalización de las observaciones que pueden hacerse a nivel físico-químico). Los sistemas que reciben cantidades de energía superiores a sus necesidades de funcionamiento en equilibrio dinámico, reaccionan con oscilaciones cada vez más amplias alrededor de su punto de equilibrio y se alejan más y más de éste. Las oscilaciones corresponden a la tendencia del sistema de eliminar la energía sobrante. Retomando una curiosa experiencia acerca de la ebullición del agua en un recipiente, en el que aparecen estructuras hexagonales en el líquido – hecha por el francés Bénard, en 1908 – Prigogine mostró que este proceso de sobrealimentación energética lleva a un punto crítico de desequilibrio en el cual la disipación de energía por medio de las grandes fluctuaciones empieza a manifestarse por la aparición de nuevas estructuras y conduce a la formación de un sistema más complejo (a condición que los desequilibrios no destruyan antes al sistema original)</p>
            <p>En el punto crítico, la constitución futura del sistema de mayor complejidad se organiza alrededor de un núcleo organizador cuya aparición y forma primitiva parecen aleatorias. Una vez organizado y estabilizado el nuevo sistema en una escala superior de complejidad, el teorema de producción mínima de entropía recupera su validez y el sistema tiende a estabilizarse.</p>
            <p>Aparentemente este modelo de emergencia de la complejidad a un nivel superior se aplica a la situación planetaria actual de la humanidad como consecuencia de la sobrealimentación en energía de nuestros sistemas tecno-industriales en todos los niveles desde un siglo y medio. Hasta la producción aumentada de entropía parece corresponder no sólo a las estructuras disipativas descriptas, sino también a la producción masiva de desechos que caracteriza nuestra organización actual.</p>
            <p>Sin embargo existe un interrogante muy serio acerca de la posibilidad de mantener el nuevo nivel de uso de energía, ya que casi todo el proceso evolutivo reciente se ha basado en la utilización de energías fósiles no renovables. En todo caso, ello es otro tema, que no se considera en esta nota, por más crucial que podría ser.</p>
            <h2>La reorganización de los procesos y estructuras de los sistemas emergentes</h2>
            <p>Los individuos – o los sistemas – que entran a participar en un sistema de nivel superior de complejidad pierden ciertas características y adquieren otras. Cuando un elemento entra en un sistema, su relación directa con su entorno desaparece o disminuye sensiblemente. El llanero solitario se hace peón de estancia al igual que la abeja se hace reina, criadora o recolectora. Ello también es una regla sistémica muy general, como lo atestigua ya la diferencia entre el átomo libre de hidrógeno y el mismo átomo dentro de la molécula de agua, de metano o de ácido sulfúrico.</p>
            <p>Este principio reaparece bajo aspectos en apariencia muy diferentes en sistemas muy diversos. Bertrand de Jouvenel, por ejemplo, lo ha enunciado en materia de formas de organización política diciendo que “El estado nacional es demasiado grande para ocuparse eficazmente de los asuntos chicos, y demasiado pequeño para ocuparse de los asuntos grandes”. Esta reflexión, que parecía escandalosa hace 30 años se verifica ahora ampliamente en la Unión Europea, donde resurgen los particularismos regionales dentro de los estados nacionales al mismo tiempo que éstos pierden prerrogativas a escala comunitaria. En muchos casos se debate el llamado “principio de subsidiaridad”, en virtud del cual se trata de definir cuáles son las atribuciones legales, económicas, financieras, administrativas y otras que deben ser reconocidas a cada nivel y dentro de que límites. Al mismo tiempo, se presentan nuevos problemas de coordinación entre los distintos niveles porque abundan los asuntos que conciernen a los tres niveles (por ejemplo los problemas de contaminación ecológica y los de repartición de los subsidios agrícolas comunitarios).</p>
            <p>Otro aspecto muy significativo es que el sistema de nivel superior de complejidad adquiere una permanencia en el tiempo mucho mayor que sus elementos. Al igual que las células en nuestro cuerpo, cada uno de nosotros dura mucho menos que la sociedad de la cual participa… y es probablemente incapaz de entender todo el significado y todas las implicancias de esta permanencia a largo plazo.</p>
            <p>En este sentido, sería sin duda muy interesante tratar de entender lo que explica la permanencia de la cultura china en los últimos 2500 años, o de la Iglesia Católica en los últimos 2000 años en comparación con casi todos los otros sistemas culturales que, cuando mucho, no pasaron de 500 a 1000 años de sobrevivencia.</p>
            <p>Un tercer carácter sistémico relacionado con el cambio de escala es precisamente la mayor estabilidad y capacidad de sobrevivencia del sistema grande en comparación con el de nivel inferior. Por ejemplo, las grandes hambrunas medievales europeas parecen haber tenido un carácter catastrófico en comunidades locales o regionales en buena parte por la imposibilidad de transportar alimentos a gran escala de una región a otra. En comparación, buena parte de las cosechas se perdieron en 1976 en ciertas zonas de Europa Occidental a causa de una gran sequía. Pero no hubo ninguna hambruna porque el sistema de producción y distribución de alimentos ya se había establecido a nivel internacional y existían reservas suficientes y movilizables en otras zonas.</p>
            <p>En resumen, es importante tratar de entender qué procesos y estructuras cambian en cualquier fenómeno de globalización y, además porqué, cómo y cuándo cambian antes de querer resistir a algunos aspectos que parecen negativos o adelantar otros que parecen positivos.</p>
            <h2>El futuro de los grandes sistemas</h2>
            <p>Debemos además percatarnos de una complicación suplementaria y tratar de evaluar en qué medida afecta nuestra capacidad de previsión y de manejo. En el transcurso de los años recientes se ha descubierto por qué razones los sistemas complejos están sujetos a crisis violentas, súbitas e imprevisibles – o sea, porqué son caóticos.</p>
            <p>El concepto mecanicista heredado de la física clásica es básicamente determinístico. El modelo de la mecánica celeste – aunque también afectado ahora por el caos – hacía pensar que las mismas causas siempre precedían a los mismos efectos. Ello era – y sigue siendo – cierto en situaciones lineales donde las perturbaciones exteriores e interiores son negligibles. Pero tales situaciones ideales no son propias de los sistemas complejos.</p>
            <p>Lo que se observa en la realidad es la aparición repentina de crisis inesperadas, de desastres imprevistos o, eventualmente de sucesos positivos que sorprenden. Ejemplos de ello pueden ser: grandes terremotos, vastos y violentos incendios forestales, huracanes destructivos, aludes y avalanchas, descompensaciones cardíacas, pánicos financieros o bursátiles, revoluciones que empiezan de lejos con incidentes en apariencia casuales. Dejando de lado las perturbaciones externas, los fenómenos caóticos resultan de la ampliación masiva de ciertas pequeñas perturbaciones internas del sistema.</p>
            <p>La teoría científica del caos está aún edificándose… y es muy abstracta. Sin embargo, es posible entender a grandes rasgos el concepto general. La construcción, la estabilización y la permanencia de los sistemas, sus estructuras y sus procesos serían obviamente imposibles si cualquier cosa pudiera pasar en cualquier momento. Todos sabemos que, por lo general, es posible hacer previsiones relativamente exitosas al menos a corto plazo en cuanto a la evolución futura de muchas situaciones, aún complejas. La razón es que los sistemas existentes son el resultado de una larga y coherente evolución de muchos factores. En síntesis, no puede dudarse que existe un determinismo global y específico que condiciona en amplia medida y en general lo que puede o no puede acontecer. Pero la restricción crítica estíen la expresión “en amplia medida”, y proviene del comportamiento interno de los elementos del sistema.</p>
            <p>Si bien cada elemento está por lo general estrechamente condicionado para su sobrevivencia al cumplimiento de ciertas pautas, ello no le quita totalmente su capacidad propia de acción. Un excelente ejemplo es el comportamiento individual del especulador o inversor bursátil dentro del mercado. Si bien se guía por un conjunto de informaciones y percepciones sobre tendencias que comparte con la mayoría de los demás participantes, tiene también sus propios impulsos o necesidades que puede compartir – o no – con un número de otros. El mecanismo de cualquier corrida bursátil es siempre una realimentación positiva a partir de una acción inicial específica y no pocas veces individual y circunstancial. Un simple rumor en un mercado seriamente sobrevalorado puede provocar una corrida sobre un valor, y por una reacción gregaria debida a una suerte de contagio psíquico, propagarse a todo el mercado. La comparación con las langostas migratorias y con los roedores escandinavos quizás no sea del todo improcedente.</p>
            <p>Sin embargo, después de un tiempo, estos episodios caóticos se ven limitados por las condiciones más generales del sistema y, a la larga, el movimiento general parece determinado por estas condiciones que, curiosamente, tienen también un aspecto estadístico.</p>
            <p>En resumen, todo sistema complejo es a la vez:</p>
                <ul>
                    <li>en principio globalmente determinado en el largo plazo</li>
                    <li>localmente y por momentos indeterminado</li>
                </ul>
            <p>Ello implica necesariamente un cierto grado de imprevisibilidad para el observador. Siempre lo imprevisto puede surgir, en especial cerca de los límites extremos de las condiciones de estabilidad, o después de un largo período de normalidad, que puede simplemente disimular la lenta acumulación de una situación de desequilibrio (como entre placas tectónicas el caso de los terremotos, por ejemplo) Ello justifica el refrán que dice:”Lo que no ocurre en cien años, ocurre en un instante”.</p>
            <p>La relativa imprevisibilidad implica asimismo la imposibilidad de la planificación rigurosa y perfecta, en especial en relación a un futuro relativamente lejano. Lo cual implica a su vez que toda planificación debe ser reiterativa, o sea reconsiderarse frecuentemente en función de la constante observación del comportamiento del sistema.</p>
            <p>En el pasado, se han registrado sorpresas muy grandes en la evolución de los grandes sistemas, en especial los sistemas políticos y culturales. Estamos en el presente confrontados con el fenómeno opuesto, la construcción de nuevos grandes sistemas mundiales – y quizás del mayor de todos, el sistema Humanidad-planeta. El uso exploratorio de los modelos sistémicos para tratar de entender de qué manera se plasmarán las características de estos sistemas podría posiblemente evitarnos:</p>
                <ul>
                    <li>interpretaciones superficiales</li>
                    <li>ilusiones en cuanto a nuestro poder de influenciar masivamente su futuro.</li>
                </ul>
            <p>Es de esperar que, asimismo, nos permitirá discernir los límites, las posibililidades – y en algunos casos, los riesgos – de nuestra acción personal y grupal.</p>
        </div>
    )
}