import pdf from "../../../resources/documents/Los-Complejos-Mundos-de-la-Vida.-Nestor-Dominguez.pdf"
export const Article10 = () => {
    return (
        <div>
            <p className={"align-right"}>“Estoy convencido de que en un principio Dios hizo un mundo</p>
            <p className={"align-right"}>distinto para cada hombre, y que es en ese mundo que está</p>
            <p className={"align-right"}>dentro de nosotros mismos, donde deberíamos intentar vivir”</p>
            <p className={"align-right"}>Oscar Wilde.</p>
            <h2 className={"align-center"}>Ser “ciudadano del mundo”.</h2>
            <p>Nuestra vida está poblada de referencias a la “globalidad”. Los franceses, en cambio, se refieren a la “mundialidad”. La globalidad tiene que ver con un proceso que se viene produciendo en los habitantes humanos de la Tierra que acaban de percibir que nuestro planeta es uno y que debemos cuidarlo. La cuestión es que se trata de “un punto azul pálido” (1) dentro del contexto universal y que el hombre no sólo vive de la Tierra que tiene bajo sus pies sino que en una “mundialidad”, que es mucho más amplia. Pero también es muy cerrada porque al punto antedicho lo hemos dividido en regiones y países, trazando límites en un punto. Esto último es geométricamente inadmisible; la razón es que el punto no admite límites, si bien es azul gracias al mar, está “pálido” y va tomando la palidez de la enfermedad. No obstante trazamos límites, hasta en el mar, y estamos “enfermos” de algo. ¿Será de poder?</p>
            <p>Es por todo esto que para desarrollar el tema me pondré del lado de los franceses y escribiré sobre los mundos reales y virtuales en que vivimos física y espiritualmente.  Hemos salido de nuestra Tierra al espacio ultraterrestre pero también contamos con la habilidad de trasladarnos a otros mundos ficticios en un turismo muy placentero. En esto incide el hecho de que cada uno de nosotros tiene una imagen del mundo que es distinta y particular.</p>
            <p>Por otra parte tenemos constancia que vivir en este mundo, en el que nacimos, es sumamente complejo y que ello crece en su evolución. Esto ocurre, quizás, de manera <b>exponencial</b> y tenemos miedo al futuro. Quizás sea esta la razón por la cual los argentinos vivimos recordando y revisando nuestra historia o deseamos retener sólo el presente mientras el futuro se nos viene encima.</p>
            <p>Si fuera posible preguntarle al hombre de las cavernas si su mundo era simple o complejo seguramente nos hubiera contestado que <b>simple</b>. La razón de su respuesta tendría dos ingredientes fundamentales; el primero sería que, por estar inmerso en la naturaleza, le resultaba simple cumplir con todos sus preceptos por ser él también natural y el segundo obedecería a que por ser el núcleo de sus relaciones, fundamentalmente familiares, las relaciones con su entorno humano también serían de origen natural. Esta simplicidad natural la hemos perdido con el devenir de los milenios.</p>
            <p>Hecha la misma pregunta a un hombre actual, inmerso en los logros de la modernidad, seguramente contestará que <b>complejo</b>. Son tantas las leyes que le hemos atribuido a la naturaleza y tantas las que pensamos y sentimos desconocer que nuestro entorno natural, del que estamos voluntariamente casi segregados, que nuestra relación con la naturaleza se ha hecho mucho más problemática. Por otra parte, nuestro relacionamiento con el resto de los hombres de la humanidad,  sea real o virtual y más allá del propio entorno familiar, se ha hecho tan amplio como complejo y tenemos la sensación que las muy diversas leyes humanas, aunque se cumplieran, es muy difícil que puedan ordenarlo. Se han generado centenas de miles de leyes nacionales y cientos de tratados, acuerdos y convenios internacionales de dificultosa aplicación y conocimiento por los interesados. Esto nos lleva a pensar que al mundo ya no lo arregla nadie y que a nuestro país tampoco. Los llamados estadistas son cada vez menos y las acciones políticas cada vez nos resultan cada vez más insatisfactorias. La Organización de las Naciones Unidas (ONU), una unión de las naciones creada ante el espanto de la Segunda Guerra Mundial, parte del hecho que se ha tornado ficticia ante guerras posteriores de distinta naturaleza y el terrorismo internacional responde a expresiones ideológicas, religiosas y filosóficas, de origen local. La cuestión básica de hecho es que las naciones están desunidas a través de los mismos límites, cuidadosamente trazados, que las separan y por ideas respaldadas con la agresividad propia de nuestra especie.</p>
            <p>Es un hecho que, a pesar de todos los esfuerzos de científicos y legislativos existen “lagunas” de los derechos natural y social que no podrán nunca ser llenadas con “las aguas” del conocimiento científico y del derecho.</p>
            <p>Creo que es esta situación histórica la que nos ha llevado a crear las <b>ciencias de la complejidad</b> para tratar de entender y comprender el mundo en que vivimos. Sobre éstas ciencias desarrollaré otro artículo posterior orientado a sus aplicaciones en el mar.</p>
            <p>Leer artículo completo: <a href={pdf}>Los Complejos Mundos de la Vida. Nestor Dominguez</a></p>
        </div>
    )
}