export const Article7 = () => {
    return (
        <div>
            <h2>Especialización, Disyunción, Disgregación</h2>
            <p>A partir del Renacimiento comienza en Europa Occidental una profunda transformación de los modos de pensamiento, cuyas consecuencias efectivas se manifiestan claramente desde apenas unas decenas de años.</p>
            <p>Se dice que el famoso humanista italiano Pico della Mirandola podía, todavía a fines del Siglo 16, disertar “de omni re scibili” (de cualquier cosa que podía saberse).</p>
            <p>Ello era posible, al menos en teoría, porque la suma de todos los conocimientos – auténticos o no – era aún bastante limitada y su profundización no exigía estudios muy especializados, al menos en el sentido actual de la palabra. Bastaba conocer a los grandes autores del pasado y glosar más o menos hábilmente sobre los textos heredados.</p>
            <p>Es a partir del Siglo 17 que las ciencias, y al mismo tiempo las técnicas empiezan a diferenciarse y a progresar de una manera significativa mediante el uso de la experimentación y la elaboración de hipótesis y teorías que se busca comprobar racionalmente.</p>
            <p>Es así que el Siglo 17 es por ejemplo el de Galileo (luneta astronómica) y de van Leeuwenhoek (microscopio), marcando el nacimiento de la astronomía y de la biología como ciencias, primero de observación y después experimentales.</p>
            <p>En los mismos años, Descartes publicó su “Discours de la Méthode” (1637), en el que recomendaba dividir lo complejo en sus partes más simples hasta llegar al buen entendimiento de éstas, para despues reconstruir la totalidad. Ello es el programa mismo de las ciencias en los últimos tres siglos.</p>
            <p>La combinación de la metodología con el uso de los instrumentos de observación y de experimentación llevó en efecto a una extraordinaria explosión de los conocimientos.</p>
            <p>De hecho, el término explosión conviene muy bien al fenómeno que se produjo. Las incipientes ciencias se dividieron primero en grandes ramas: ciencias abstractas, ciencias físicas, ciencias llamadas naturales, con un lugar especial para la medicina, que ya tenía una larga trayectoria histórica y reciente: Creación de la anatomía por André Vésale (1544).</p>
            <p>A partir del Siglo 18, y mucho más en los Siglos 19 y 20 el proceso de fragmentación se aceleró siempre más. Por ejemplo, la química se separó de la física y pronto se subdividió en química inorgánica y química orgánica. En la actualidad, ambas están ya fraccionadas en una multitud de especialidades más estrechas, como por ejemplo la química de los polímeros orgánicos, o la química del carbón 60 (los así llamados Buckyballs).</p>
            <p>Por otra parte, aparecieron interdisciplinas, como por ejemplo la bioquímica, que no tardaron en separarse en nuevas especialidades y en subdividirse a su vez.</p>
            <p>Las múltiples disciplinas llevaron asimismo a la aparición de tecnologías nuevas, de creciente complejidad. Las aplicaciones prácticas de estas tecnologías nos rodean ahora en todos los momentos de nuestra existencia. De hecho vivimos ya cada vez más en una especie de envoltura artificial basada en la energía, las radiaciones y los artefactos de toda clase.</p>
            <p>Además, vivir implica ahora con frecuencia, actuar usando estos artefactos. Pero este uso tiene algunas características muy significativas, que pocos entre nosotros advierten.</p>
            <p>Cada cual tiene una relación doble con los artefactos. Están los que saben usarlos y los usan efectivamente porque son de manejo simple, como por ejemplo el coche o el teléfono, o porque uno mismo es especialista adiestrado en su uso, como por ejemplo, el caso del médico radiólogo.</p>
            <p>Por otra parte, están los artefactos que usan los demás y tienen un impacto sobre nosotros. Por ejemplo el ruido a veces perturbador de los reactores de aviones, o los eventuales restos de insecticidas en nuestra comida.</p>
            <p>Sin embargo, al mismo tiempo vivimos en un entorno artificial que se nos hace más y más incomprensible. Si bien usamos sin dificultad un teléfono o un coche, somos por lo general incapaces de repararlos en caso de necesidad. Raras veces entendemos la ciencia y la técnica que los hicieron posibles y este es un hecho que nos deja indiferentes.</p>
            <p>El mundo de las especializaciones es ahora un universo descoyunturado y hasta cierto punto disgregado. Pero por otra parte, nos ha hecho parte de grupos mucho más amplios porque nuestra misma inoperancia frente a muchos problemas técnicos nos obliga a relacionarnos con los individuos que poseen los conocimientos que podemos necesitar en cualquier momento. También nos encontramos ahora comunicados, a veces entre países y continentes, con gente que nunca hubiésemos conocido sin los medios modernos de comunicación, materiales o electrónicos. Y sin embargo estas relaciones son mucho más abstractas que las que tenemos con nuestros colegas o amigos.</p>
            <p>Esta extraña disgregación hiperconectada atañe asimismo al entorno en el que nos toca vivir. Las acciones, decisiones (o errores) de algún burócrata, ingeniero, científico o capitán de industria, tomadas a veces en las antípodas, nos pueden afectar personalmente en lo inmediato o en lo mediato. Algunas personas viviendo a miles de kilómetros de Chernobyl contraerán cáncer como resultado de la explosión del año 1989. Y, cosa más extraña aún, muchos pescadores de altamar se quedarán sin trabajo a causa de la depredación masiva de los mares… por muchos pescadores de altamar, usando técnicas cada vez más “eficientes” para vaciar los océanos.</p>
            <h2>Los Efectos Sinergéticos</h2>
            <p>Se trata de problemas de un nuevo tipo, a los cuales nuestra ciencia especializada no tiene respuestas. Corresponden a la última prescripción metodológica cartesiana que era recomponer la totalidad después del estudio de las partes, esto que suele llamarse ahora reduccionismo.</p>
            <p>A decir verdad, Descartes no explicó cómo se debia efectuar esta reconstrucción. Mientras tanto, los efectos de su primera prescripción, el estudio de las partes, fueron tan extraordinariamente exitosos en sí mismos que ya nadie se preocupó mucho de las totalidades complejas.</p>
            <p>Sin embargo, si se examina con más detenimiento la propuesta reduccionista, se ve que equivale a suprimir la casi totalidad de las conexiones que unen los elementos en conjuntos complejos coherentes y funcionales. Apenas se conservan relaciones funcionales simples que responden al determinismo riguroso, pero en extremo simplificado, del tipo “Una causa, un efecto”.</p>
            <p>Además esta simplificación, para pasar a la experiencia repetible y sacar de la misma conclusiones de valor general exige agregar la dudosa condición “et ceteris paribus” – “admitiendo que todas las otras condiciones queden iguales, tal causa provocará siempre el mismo efecto”. Para que las otras condiciones queden iguales, se debe en general artificializar la experiencia, recortándola de su entorno, precisamente para protegerla de variaciones consideradas en tal caso específico como “parásitas”.</p>
            <p>Todo ello nos vale un mundo natural más y más desarticulado en pedazos enajenados unos de otros. Durante muchos años, ello no se ha percibido porque en el mundo de los artefactos que hemos creado, somos capaces de armar sistemas complejos artificiales al crear nosotros mismos las coherencias que deseamos. Pensemos, por ejemplo, en la complejidad armada de un avión.</p>
            <p>Tanto en la desarticulación de lo natural como en la articulación de lo artificial, nos hallamos curiosamente inconscientes de la existencia o del carácter esencial de la necesidad de las interrelaciones e interacciones.</p>
            <p>La especialización, tan beneficiosa en un sentido, parece habernos cerrado el paso al cabal entendimiento de las sinergías, aún paradójicamente de las que somos capaces de organizar.</p>
            <p>Veamos uno o dos ejemplos, empezando por un caso de antología negra: el drama del Mar de Aral (ver T.G.S. al dia nr.6 de GESI).</p>
            <p>En este tremendo caso, es obvio que los responsables del desastre no tenían la menor percepción de las sinergías naturales que unían todas las partes del mega-sistema Mar de Aral, incluyendo las comunidades vegetales, animales y humanas. Pretendieron introducir cambios en aparencia bastante coherentes y positivos. Pero no se dieron cuenta de las consecuencias destructivas de su programa (o peor, las subestimaron o las pasaron por alto).</p>
            <h2>Otro ejemplo es el de la caza de ballenas</h2>
            <p>En los años 30 la masacre masiva de estos cetáceos parecía todavía una empresa a la vez provechosa y hasta heroica, al estilo Moby Dick. Nadie parecía tener la menor idea que se corría el riesgo de matar a tantas ballenas que su rarefacción terminaría por exterminar el mismo negocio de su caza. Apenas en los últimos años se ha llegado al concepto de la explotación sustentable, aunque los últimos “vivos” están todavía tratando de sacar las últimas ballenas, en violación abierta a la vez de las reglas que se trata de establecer, … y del mismo sentido común.</p>
            <p>Es que las sinergías no son simplemente funciones determinísticas lineales. Se refieren a funciones de funciones interconectadas y ello en niveles crecientes de complejidad e integración. Por otra parte, se dan por el juego de reacciones de un tipo que quedó totalmente incomprendido hasta hace unos cincuenta años atrás y que aún hoy son muy generalmente ignoradas. Se trata primero de las retroalimentaciones, segundo de los efectos de masas, de los cuales no pocos son “perversos” (según el término acuñado por el sociólogo francés R. Boudon) y tercero, de los fenómenos emergentes en niveles de creciente complejidad.</p>
            <h2>La Descripción Sistémica de las Situaciones Complejas</h2>
            <p>La aparición y el desarrollo progresivo durante los últimos 50 años de los modelos y conceptos cibernéticos y sistémicos será quizás considerada en el futuro como una necesaria reacción inmunológica de la humanidad contra los peligros en los que se coloca a sí misma.</p>
            <p>Reflexionando sobre las sinergías, como resultados de distintos tipos de interacciones, podemos considerar algunos ejemplos de estos nuevos modelos explicativos.</p>
            <h2>El concepto de feedback (retroalimentación)</h2>
            <p>En un proceso, un feedback es un efecto que se retrotrae sobre la causa. Un ejemplo clásico simple es el regulador de la descarga del baño, que provoca la admisión de agua en el tanque cuando éste se ha vaciado, pero cierra el acceso del agua cuando se ha llenado.</p>
            <p>Se trata esencialmente de un mecanismo de compensación. El vaciamiento provoca, por medio del descenso del flotador, la apertura de la válvula de acceso del agua. Pero el progresivo llenado del tanque es lo que provocará después la obturación del acceso de agua, evitando así una inundación desastrosa o un desperdicio incontrolado del agua . Un feedback de control eficiente actúa, consiguientemente, por fases alternas de acción positiva y negativa.</p>
            <p>Es interesante considerar lo que ocurre cuando opera aisladamente un feedback positivo o uno negativo.</p>
            <p>El feedback positivo aumenta o acelera el proceso en curso. Un incendio, por ejemplo, se propaga y se extiende exponencialmente hasta tanto encuentra combustible para desarrollarse. Sin embargo, cuando ha quemado todo lo que estaba a su alcance, se extingue. Ello demuestra que el feedback positivo es a la vez destructivo del proceso que sobrealimenta, y auto-destructivo. Lo mismo vale en biología, por ejemplo en el caso del cáncer.</p>
            <p>Al contrario, el feedback negativo ralenta y contrae el proceso. Ello se observa muy bien en el regulador del tanque de agua: a medida que el flotador sube, el ingreso del agua va mermando, hasta que cesa por completo. En cierto sentido, el feedback negativo, al igual que el positivo se auto-anula.</p>
            <p>Si se quiere regular un proceso, el problema es mantenerlo dentro de límites tales que no se auto-destruya.</p>
            <p>Si consideramos por ejemplo la caza de las ballenas, la captura masiva de ballenas fue al principio un feedback positivo: Hubo cada vez más barcos balleneros para explotar el recurso y se cazaron cada vez más ballenas durante 40 o 50 años. Pero el exceso mismo de la caza terminó por aniquilar el negocio: la escasez creciente de ballenas actuó como un feedback negativo sobre las flotas balleneras. La reacción fue la creación de un organismo mundial para la regulación de la caza. En principio habrá que permitir durante muchos años la reproducción natural de las ballenas (si no es demasiado tarde!) para reconstituir el recurso. Mientras tanto hay que aplicar un feedback negativo riguroso a la caza… cosa que, frente a la codicia humana de corta vista, es más fácil pregonar que aplicar.</p>
            <p>Mantener un proceso dentro de límites de estabilidad dinámica (o en otros términos, de sustentabilidad) supone por consiguiente una alternancia de feedbacks positivos y negativos. La equilibración y el calibrado de estos feedbacks es lo que determina la amplitud de las oscilaciones.</p>
            <p>Además, el estudio de los sistemas naturales, haciendo caso omiso de las intervenciones humanas (algo ahora muy difícil en práctica), debe instruirnos acerca de las regulaciones preexistentes a estas intervenciones, ya que prácticamente todos los fenómenos naturales son oscilantes dentro de límites de estabilidad. Ello nos enseña que nuestros propios controles pueden ser – o no ser – compatibles con las regulaciones naturales. El concepto y los modelos de feedback son pues necesarios para un entendimiento genuino de nuestras relaciones con nuestro entorno. Muchos otros ejemplos pueden darse.</p>
            <p>Sin embargo hay uno que es tan general y abarcativo que no se lo puede pasar por alto. Se trata del uso creciente de las energías naturales por el hombre desde el Siglo 19. Este uso siempre más masivo ha introducido un feedback positivo dentro de casi todas nuestras actividades y es el motor esencial de la aceleración de todos los procesos que ahora registramos . Se trata obviamente de un feedback positivo, o sea potencialmente destructivo y auto-destructivo. El hecho debe considerarse como una poderosa llamada de atención para toda la humanidad. Habrá feedbacks negativos compensatorios? Será la naturaleza quien se encargará de aplicarlos? O en qué medida tendríamos que idearlos y ponerlos en aplicación para evitar desastres masivos?</p>
            <p>Esta es en realidad la cuestión crucial subyacente al tema de la sustentabilidad en el marco de la globalización.</p>
            <h2>Los efectos de rebote</h2>
            <p>Otro fenómeno sistémico importante son los efectos de rebote, o sea el reemplazo de un efecto por otro en un proceso manipulado, o el aumento del efecto en forma indirecta.</p>
            <p>Nuevamente el caso de la caza de ballenas nos ayudará a ilustrarlo. El término de “ballenas” es un génerico que cubre un cierto número de especies diferentes de estos cetáceos, como por ejemplo el cachalote, la ballena franca, el rorqual, la ballena minke.</p>
            <p>Los cazadores se dedicaron primero al casi exterminio de las especies más grandes, como el cachalote. Cuando ya se encontraban pocos ejemplares, desplazaron su actividad sucesivamente hacia las especies de tamaño relativamente menor… y prosiguió el exterminio.</p>
            <p>En un sentido algo diferente, J. van Gigch hacía notar recientemente que muchos adelantos técnicos, que parecen susceptibles de disminuir el uso de materias primas y/o de energía en algún proceso de producción, terminan por aumentar el consumo de los mismos. En efecto, el abaratamiento del producto suele producir un aumento de su consumo, a veces considerable y, por consiguiente un aumento de los insumos necesarios.</p>
            <p>Ello equivale a un desplazamiento del feedback positivo, que intervendrá así, de nuevo, en forma derivada y no pocas veces agravada. Este efecto de rebote es poco conocido y menos aún comprendido, lo cual puede tornarlo muy peligroso.</p>
            <h2>Los efectos de masas</h2>
            <p>Entre los efectos sistémicos más decisivos figuran los efectos de masas. Una langosta en nuestro jardín puede destruir una o dos plantas. Pero 5.000 millones de langostas migratorias pueden destruir la agricultura de todo un país en cuestión de horas.</p>
            <p>Del mismo modo, nuestro coche personal parece un animal mecánico bastante dócil e inofensivo. Pero 100 millones de vehículos en el planeta están contribuyendo seriamente a cambiar el tenor en monóxido y dióxido de carbón y quizás la temperatura de nuestra atmósfera global y, de paso, están tornando caótico y, sin duda pronto paralítico, el tránsito en nuestras ciudades.</p>
            <p>La comprensión de estos “efectos perversos” es aún escasa y poco difundida, una situación que nos impide guarecernos de sus consecuencias.</p>
            <p>En general resultan de un juego coordinado de feedbacks positivos. [(descripción a) del proceso de gregarización de las langostas migratorias – b) del proceso de desarrollo del parque automotor mundial, muy interesante por los aspectos psico-sociales que presenta)].</p>
            <p>La fase de crecimiento exponencial parece resultar en sí misma de un factor de refuerzo, aún no claramente entendido. Ulteriormente surge – más allá de un umbral crítico donde se destruye la posibilidad de una regulación “suave” – un cambio catastrófico que toma el aspecto de una transformación cualitativa. Sin embargo, el aspecto “catastrófico” no hace más que reflejar el hecho de habernos dejado sorprender por la evolución de un proceso que no hemos sabido percibir y comprender en tiempo oportuno.</p>
            <p>Necesitamos por lo tanto una teoría de los umbrales cuantitativos críticos, de las transiciones de fases en los sistemas humanos complejos, y de las causas de los procesos que llevan a los mismos. La Sistémica está generando tal teoría en los años recientes, en relación con los conceptos de sinergía (H. Haken) y del caos (Lorenz, Smale, etc…).</p>
            <h2>El proceso de emergencia de la complejidad</h2>
            <p>Sin entrar en consideraciones demasiado técnicas, debemos señalar otro concepto sistémico de gran importancia práctica para nuestro futuro globalizado: el de emergencia.</p>
            <p>I. Prigogine ha extendido considerablemente durante los últimos 40 años los conceptos básicos de la termodinámica.</p>
            <p>En particular ha enunciado un “Principio de Producción Mínima de Entropía” (La entropía siendo la medida de la degradación irreversible de la energía hasta que no puede más producir ningún trabajo útil o, lo que equivale, no pueda más generar orden compensatorio). Según este principio, el equilibrio dinámico estable de cualquier sistema corresponde al punto en el que este sistema funciona de la manera más económica del punto de vista del gasto permanente de energía. El Principio da cuenta, entre otras cosas, de la estabilidad a largo plazo de muchos sistemas humanos arcaicos que habían logrado una simbiosis con su entorno en base al nivel estable de desarrollo técnico alcanzado.</p>
            <p>En el pasado, este nivel conoció sólo unos pocos saltos cualitativos separados por largos períodos sin grandes modificaciones (ver mi nota sobre “El camino de la Globalización”)</p>
            <p>Esta situación, como señalado anteriormente, empezó a cambiar de manera acelerada a partir de la mitad del Siglo 19. Desde el momento en que la nueva tecnología permitió la explotación masiva de las energías fósiles y su uso igualmente masivo en un nuevo universo de máquinas multiplicadoras del poder humano, toda dinámica estable se tornó imposible. El cambio se acompañó con un considerable desarrollo de las estructuras sociales existentes y con la aparición masiva de nuevas estructuras (como por ejemplo las burocracias, los sindicatos y la policía de tránsito). Hubo emergencia de estructuras complejas nuevas.</p>
            <p>La Termodinámica de Prigogine tiene también mucho que decirnos sobre este tipo de procesos, que aparece desde el nivel químico, por lo menos (Reacción de Belousov-Zhabotinsky, por ejemplo).</p>
            <p>En estos casos un sistema empieza a recibir energía en exceso, que no le permite más mantener su nivel estable de producción de entropía, o sea de eliminación de la energía degradada hacia el entorno – simplemente para mantener estable su nivel de organización. Prigogine mostró que en presencia de influjos excesivos de energía, el sistema empieza a desestabilizarse. Ello se manifiesta por una actividad en aumento y fluctuaciones más y más amplias, que acompañan la disipación creciente de la energía. En un cierto momento, el proceso de disipación se torna crítico y las fluctuaciones franquean un definido umbral de inestabilidad, de manera irreversible. Esta fase se caracteriza por un creciente desorden, pero lleva al sistema a un punto crítico. Si no se auto-destruye o es destruído, muta hacia formas nuevas de organización, que se dicen “emergentes”. Estas se caracterizan generalmente por la aparición de nuevas regulaciones jerárquicas de carácter dominante en relación con las anteriormente existentes. En otras palabras, el sistema se “globaliza”, o sea reordena sus elementos para pasar a un nivel de ordenamiento superior y más complejo. En este nivel, se pasa a un tipo nuevo de estabilidad dinámica y a un nivel superior de “producción mínima de entropía”.</p>
            <p>El momento del franqueo del umbral corresponde a un punto crítico de bifurcación, en el que toda la organización futura más compleja del sistema está en juego. Esto significa que en este momento puntual, se abren diversas posibilidades hacia posibles formas diferentes de organización, si bien no cualquiera sea posible. Lo que pasará en este momento es decisivo y, en los sistemas humanos, abre quizás un breve espacio para el diseño de proyectos alternativos.</p>
            <p>La palabra “quizás” es clave, porque en realidad no se sabe hasta ahora si esta medida relativa de supuesto indeterminismo puede realmente aprovecharse, y en tal caso, de qué manera.</p>
            <p>De hecho, la toma de control del hombre sobre su entorno por medio de la tecnología y las consecuencias de este hecho sin precedentes en el planeta, ha cambiado profundamente el sentido de nuestra responsabilidad: En la medida misma en que nos hemos arrogado el derecho de manejar el sistema planetario, hemos también de responsabilizarnos por lo que hacemos. Este tema ha sido desarrollado con mucha profundidad por Hans Jonas en su obra “Principio Responsabilidad”.</p>
            <p>Debe sin embargo destacarse que tanto el ejercicio de la responsabilidad como el deseo (complementario) de diseñar sistemas ecológicos, económicos, sociales o políticos mejor adaptados deben necesariamente empezar por una mejor comprensión de todos los sistemas de los que somos partes o que pretendemos manejar. Sin ello no seremos responsables, sino irresponsables.</p>
            <p>Por esta razón, me he dedicado a un censo comentado de todos los conceptos y modelos sistémicos y cibernéticos que, a mi entender, nos pueden ayudar en esta tarea fundamental.</p>
            <h2>BIBLIOGRAFIA</h2>
            <p>François, Charles: La falta de percepción de las correlaciones en los desastres creados por el hombre – T.G.S. al dia nr.6, Gesi-AATGSC, Buenos Aires, 1990</p>
            <p>François, Charles: Diccionario de Teoría General de Sistemas y Cibernética – Gesi-AATGSC, Buenos Aires, 1992</p>
            <p>François, Charles: International Encyclopedia of Systems and Cybernetics – K.G.Saur Verlag, Munchen, Alemania, 1997</p>
            <p>Gleick, James: Chaos – Cardinal Penguin Books, Harmondsworth, Middlesex, U.K., 1987</p>
            <p>Haken, Herman: Synergetics, the Science of Structure – Van Nostrand Reinhold, New York, 1984</p>
            <p>Jonas, Hans: Das Prinzip Verantwortung – Insel Verlag – Frankfurt a.M, Germany, 1979</p>
        </div>
    )
}