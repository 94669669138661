export const Article1 = () => {
    return (
        <div>
            <p className={"align-right"}><i>Where is the Knowledge we have lost with information?<br/>Where is the
                wisdom we have lost with knowledge?</i></p>
            <p className={"align-right"}><b>T.S. Eliot</b></p>
            <p>La relación del individuo con la sociedad se halla en rápida evolución en todas las culturas. El cambio
                tecnológico y las transformacio nes económicas, demográficas y sociales resultantes tienden a disminuir
                rápidament la importancia del individuo en la sociedad, salvo si logra integrarse en ella de una manera
                no puramente numérica o estadística. Es oportuno profundizar las causas y las consecuencias probables de
                este cambio.</p>
            <h2>El individuo en las sociedades del pasado</h2>
            <p>En las sociedades prehistóricas o también arcaicas contemporáneas, todo individuo era personalmente
                importante. Cada cual era repositorio viviente de una parte significativa de la cultura del grupo, ya
                fueran técnicas de caza o artesanales (alfarería, cestería, herrería, etc.), los conocimientos botánicos
                prácticos, curanderismo, hechicería u otros. La desaparición prematura de un solo indiv iduo portador
                exclusivo de algún saber específico podía ser una catástrofe para la olectividad. Aún en el siglo XIV,
                después de la Peste Negra, fue necesario “importar” de otras regiones o de países extranjeros artesanos
                especializados porque la epidemia se había llevado a la totalidad de los poseedores de alguna técnica.
                Una de las consecuencias más extrañas de la Peste fue el aumento considerable de los sueldos de los
                artesanos especializados sobrevivientes.</p>
            <p>Por otra parte, en las sociedades arcaicas y también en las sociedades históricas preindustriales, cada
                individuo era parte de una célula social amplia, que tenía una dimensión cultural e histórica. La
                familia tradicio nal occidental por ejemplo, deriva generalmente de alguna forma clánica (del
                Gaélico:“clann” que significa “hijos” e implica parentela). Se la puede reconocer a través de toda la
                historia del mundo occidental y generalmente en todas partes. Los clanes han tenido una importancia
                determinante para la vida cotidiana de los individuos en todas las épocas. Les daban una ubicación
                definida en un grupo definido. Su situaci ón personal era función no sólo de su sexo, sino también de su
                posición relativa de parentesco (por ejemplo, ser o no ser primogénito, o tío materno). Esta situación
                podía cambiar pero sólo hasta cierto punto con la edad y la composición general del clan. Cada ubicación
                precisa tenía el sentido de un rol social con un conjunto de deberes y derechos muy definidos en
                relación con los otros integrantes del clan y con los miembros de otros clanes. En resumen, el individuo
                sabía quien era y lo que podía o debía hacer y sus parientes y vecinos también lo sabían. Todavía hoy no
                ha desaparecido del todo este modo de vida, del que persisten sobrevivencias por ejemplo en las islas
                griegas, en Cerdeña o en Escocia, para no hablar de Asia o Africa.</p>
            <p>No sólo el sistema definía la posición de los individuos sino que fue determinante en buena parte de la
                historia antigua. El rol de los clanes -o gens- en la historia de las ciudades griegas y de Roma ha sido
                descripto con toda claridad por Fustel de Coulanges en “La Cité Antique”.</p>
            <p>Nadie podía esperar sobresalir políticamente en Roma si no era miembro de una gens importante. Esta
                situación perduró hasta las p rimeras décadas del Imperio y dejó de tener vigencia únicamente con la
                aparición de fuerzas nuevas como los pretorianos, los libertos y los cristianos.</p>
            <p>Valores y normas en los sistemas sociales tradicionales</p>
            <p>Cabe preguntarse cómo este modo de vida se impuso y se mantuvo y cuál era la fuerza cohesiva que lo
                sostuvo durante milenios. El tema no ha sido muy estudiado y toda hipótesis puede apenas ser
                tentativa.</p>
            <p>La necesidad básica de toda comunidad humana para segurar su sobrevivencia es la solidaridad. Cuanto más
                reducido numéricamente el grupo, másecesitan cohesión. La protección mutua parece ser el origen básico
                de la socialidad y como tal sus raíces se hunden probablemente en los comportamientos sociales que se
                observan en los antropoides superiores, sino en formas vivientes aún mucho más arcaicas. El grito o la
                mímica de alerta advirtiendo algún peligro contiene en germen toda la semiótica y lo que representa, o
                sea motivaciones positivas o negativas generadoras de comportamientos útiles para una comunidad (aunque
                a veces sacrificatorios para algunos individuos).</p>
            <p>La filiación hacia sistemas de valores y de normas es obvia. El contenido de los sistemas de normas es un
                conjunto de pautas de comportamiento que van de lo imperativamente exigido hasta lo terminantemente
                prohibido. Pero, en todas partes la ley no escrita ha precedido a los códigos redactado s y también en
                todas partes lo sagrado parece haber surgido de lo visceral para dar a las normas de comportamiento un
                poder mágico-mítico mucho más eficiente que cualquier capcidad de razonamiento.</p>
            <p>Las sociedades aún muy arcaicas, han creado espontáneamente sistemas de valores con mitos y rituales
                porque los necesitaban para la correcta implantación de los individuos y su satisfactorio
                adiestramiento. No ha sido nunca un proceso consciente, sino más bien un fenómeno colectivo e
                inter-reactivo de auto-reproducción grupal.</p>
            <h2>La disolución de las sociedades tradicionales</h2>
            <p>En el marco de un escueto sobrevuelo del tema, no es posible estudiar los estados intermediarios que
                llevan de la sociedad arcaica a nuestras sociedades altamente integradas en un sentido organizacional,
                pero en vías de rápida desintegración como comunidades humanas.</p>
            <p>Pero el proceso es claramente visible en nuestra época. Es seguramente un resultado de la tecnificación
                rápida que conoce el mundo. La sociedad debe responder cada vez más a los imperativos de funcionamiento
                impuestos por la técnica y sus formas de organización se amoldan progresivamente a esta n ecesidad. Cada
                uno de nosotros se somete por ejemplo al sistema de semáforos de tránsito, no porque es moral sino
                porque es necesario para evitar una parálisis completa dela circulación. Ni los hechiceros, ni los
                libros sagrados tienen respuesta para esta clase de necesidades.</p>
            <p>Los individuos se ven sometidos a la presión de est a transformación de muchas maneras como por ejemplo,
                migraciones masivas, múltiples desplazamientos, adhesión a organizaciones profesionales, orientación de
                las pautas de consumo, hipertrofia de la información y presión de los mass-media, necesidad creciente de
                reciclaje profesional, masificación del ocio, destrucción de los lazos clánicos o familiares, etc.</p>
            <p>La gigantesca explosión demográfica reduce hasta lo ínfimo la importancia de cualquier individuo. Ser uno
                entre 50 millones no tiene nada que ver con ser uno en un pequeño grupo social de algunos cientos. La
                triste verdad es que el 999/1000 de nosotros somos rápida y totalmente prescindibles, muertos o vivos,
                cuando la sociedad lo determina, por causas (no digamos ni motivos, ni razones) que ni siquiera podemos
                entender en muchos casos.</p>
            <p>El resultado global es que cientos de millones de individuos se hunden poco a poco en la anomia, el
                anonimato, el descreimiento, la confusión e incluso la psicopatología. Se transforman en átomos sociales
                esconectados que vagan indefensos y sometidos a los incoherentes vaivenes de sociedades tan complejas
                que ya no se encuentran timoneles humanos que las entiendan y las sepan gobernar (pese a sus
                declamativas pretensiones).</p>
            <p>Una circunstancia agravante es que las transformaciones de las sociedades, lejos de pararse al cabo de
                algunos lustros, se aceleran sin cesar y se tornan siempre más imprevisibles.</p>
            <p>En el pasado un aspecto muy significativo de la vida humana era que el aprendizaje y la educación
                recibidos en la juventud servían a nuestros antepasados durante toda la vida y que, llegados a la
                situación de sabios ancianos, los podían transmitir como un preciado legado a las nuevas
                generaciones.</p>
            <p>Hoy día, la experiencia de los ancianos ya no es tenida en cuenta y en muchos casos se los envía a
                terminar tristemente su vida en lugares donde no molesten. La desintegración social, por consiguiente,
                se da tamb ién en la dimensión temporal, transgeneracional.</p>
            <p>No cabe la menor duda que todo ello conforma un cuadro alarmante y todo parece indicar que las cosas
                podrían empeorar, en lugar de mejorar.</p>
            <p>Cabe entonces hacerse algunas preguntas cruciales como:</p>
            <p>¿Hacia dónde irán nuestras sociedades si prosiguen las tendencias actuales? ¿Podemos hacer algo para
                remediar un estado de cosas que nos parece calamitoso?</p>
            <h2>La mega-sociedad y nosotros</h2>
            <p>Existen serias razones de pensar que el explosivo crecimiento de la especie humana en los dos últimos
                siglos es un episodio transitorio. Si no lo fuera la humanidad destruiría en pocos siglos el entorno del
                que depende para su sobrevivencia a largo plazo.</p>
            <p>Sin embargo, aún en caso de producirse una vuelta a la estabilidad dinámica, no podrá ocurrir antes de
                dos o tres generaciones y encondiciones muy diferentes de las actuales (mundialización, relativa
                igualdad de las culturas y de los niveles de vida, etc.). Ello significa que hasta los actuales niños
                tendránque vivir toda su vida en un interminable y caótico vendaval histórico.</p>
            <p>Por otra parte, la manera en que se produciría una futura y lejana estabilización y las formas de
                organización que tomarán las sociedades de siglo XXII dependerán mucho de lo que harán las tres próximas
                generaciones. Pueden por ejemplo someterse a</p>
            <p>un proceso de progresiva estadarización y automatiz ación social que llevaría a la instauración de
                sociedades cuya única lógica sería la autoperpetuación, aún a costa de la felicidad o simplemente de la
                dignidad de los ciudadanos. Del totalitarismo a la burocracia y de la propaganda a la uniformización m
                ecanizada de los comportamientos, los síntomas de tal posible “desarrollo” no escasea n en nuestros
                días.</p>
            <p>Pero quizás es posible evitarlo.</p>
            <p>El problema contemporáneo de la relación del individuo con su sociedad es que esta última creció mientras
                que sus componentes se achicaban. Si bien los individuos dominan técnicas a veces muy complejas- con el
                riesgo de transformarse en simples piezas de sistemas sociedades-máquinas – están perdiendo todo
                verdadero y profundo conocimiento personal de las cosas y ya no pueden alcanzar ninguna sabiduría. Como
                se dice corrientemente, están deshumanizándose. Por tees camino sería difícil no llegar a la sociedad
                automática que, con la eventual ayuda de una auténtica inteligencia artificial llegaría quizás a
                perseguir objetivos meta-humanos .</p>
            <p>La única salida alterna parece residir en la toma de conciencia por parte de cada individuo de sus roles
                actuales o potenciales y de la evolución de los mismos en una sociedad en transformación. Los sistemas
                tradiciona les de valores y de normas se han corroído irremediablemente, es cierto. Pero ello no implica
                que el hombre pueda vivir sin valores y normas en consonancia con su sociedad.</p>
            <p>Sin embargo, visto que no se puede ya heredarlas o conservarlas en la forma en que le fueron transmitidas
                por instrucción y educac ión tradicional, se deben adquirir de otra manera.</p>
            <p>Quizás la rebeldía juvenil nos da una indicación. Los comportamientos de sus mayores parecen
                frecuentemente incomprensibles, absurdos, hipócritas o deshonestos a los jóvenes. No admite fácilmente
                la tendencia por parte de los adultos a tratar de adaptarse como mejor puedan a los sobresaltos de una
                sociedad en mutación y a terminar elevando al rango de valores a la indefinición y la “viveza”, usando
                como normas la obediencia ciega y la adaptación oportuni sta. Sin embargo, estos adultos no han hecho
                más que tratar de sobrevivir y muchos deellos fueron también, al principio, adolescentes idealistas y
                críticos. Les pasó que no supieron cómo rehacerse de un sistema de valores y normas, a la vez adaptado
                al cambio, y de sentido humano positivo.</p>
            <p>Sólo a través de un esfuerzo de entendimiento permanente el individuo puede quizás llegar a asumir una
                responsabilidad activa, respondiendo al cambio por la creatividad.</p>
            <p>Los mayores ya no están más en condición de ser absolutos maestros de conducta. Lo que se aguarda de
                ellos es brindar testimonio de las experiencias que hicieron – muchas veces muy a su pesar- , de las
                reacciones que tuvieron, y de los resultados conseguidos, buenos ó malos.</p>
            <p>Ni valores, ni normas pueden ser ya códigos rígidos , impuestos por imprinting psico-social. Cada
                individuo debe poder reconsiderarlos en función de sus experiencias, para recuperarlos en sus propios
                términos, a fin de evitar caer en fanatismos destructores, apatía aniquilante y oportunismos
                mecanizadores.</p>
            <p>Cada individuo debería atravesar esa suerte de iniciación psico-social para poder asumir sus roles
                cambiantes y conquistar su propia personalidad, para evitar transformarse en autómata social. Los que lo
                logren ingresarán en el colectivo social de ayudar a los otros a franquear este umbral crítico de la
                conciencia personal.</p>
            <h2>Bibliografía</h2>
            <p>BAUMAN, Zygmunt Modernidad líquida – Fondo de cultura económica, Buenos Aires, 2002 (Original en inglés:
                “Liquid Modernity”, 2000)</p>
            <p>BAUMAN, Zygmunt La sociedad sitiada – Fondo de Cultura Económica, Buenos Aires, 2004 (Original en inglés:
                “Society under siege”, 2002)</p>
            <p>FRANK, Manfred Selbstbewusztsein und Selbsterkenntnis – Reclam, Stuttgart, Deutschland, 1991</p>
            <p>JACQUARD, Albert Del’angoisse a l’espoir;Leçons de écologie humaine – Calmann Levy, Paris, 2000</p>
            <p>JONAS, Hans Le Principle Responsabilité Flammarion, Paris, 1998 (Original en “Das Prinzip Verantwortung”,
                1979)</p>
        </div>
    )
}