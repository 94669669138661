export const Article5 = () => {
    return (
        <div>
            <h2>1. Abordaje de la noción de Transdisciplinariedad</h2>
            <p>Un creciente número de situaciones complejas, de las más variadas características,   emergen en el ámbito cada vez más extenso e interconectado que el hombre ocupa  en el planeta.</p>
            <p>Estas situaciones ya no pueden manejarse mediante el estudio de las características ocasionales, no coordinadas, limitadamente específicas,  de las que se ocupan múltiples disciplinas especializadas, sin duda muy útiles,  pero cada vez más aisladas unas de otras.</p>
            <p>Aún los mentados modelos interdisciplinarios  o multidisciplinarios se están tornando insuficientes en este sentido.</p>
            <p>Debemos recurrir a un enfoque organizador general, que podemos llamar transdisciplinario.</p>
            <p>Felizmente, este enfoque y el lenguaje correspondiente han emergido en los últimos 60 años bajo los nombres de “Cibernética” y “Ciencia de Sistemas” (o “Sistémica”)</p>
            <p>Ambas son más que nuevas disciplinas,   nuevas metodologías de abordaje, de entendimiento y de manejo del tipo de  situaciones   multifacéticas que se presentan  cada vez con más frecuencia,  aunque en realidad no eran del todo inexistentes en el pasado.</p>
            <p>Lo transdisciplinario es diferente y es más que lo inter y multi disciplinario.</p>
            <h3>Cómo se llegó a lo multidisciplinario?</h3>
            <p>Desde la central de 5 W a la central de 5.000 MW: de los efectos limitados, locales y previsibles a los efectos gigantescos, regionales e imprevisibles</p>
            <h3>Sin embargo,  hay insuficiencia en lo interdisciplinario:</h3>
            <p>Produce “collages”  pero no una visión global e integrada</p>
            <p>Le falta un lenguaje de comunicación entre especialidades y también de integración:</p>
            <p>Se aprecia la diferencia entre lograr concesiones recíprocas,  o lograr un entendimiento global integrador</p>
            <h3>Lo complejo no puede reducirse a lo complicado</h3>
            <p>Porque es heterogéneo: una multitud de elementos diferentes, interrelaciones diferentes, cada caso es sui-generis, entonces, la estadística no es posible</p>
            <h2>2. Algunas definiciones</h2>
            <p>Para aclarar cabalmente las diferencias conceptuales señaladas, conviene definir mejor los conceptos señalados</p>
            <h3>Interdisciplinariedad</h3>
            <p>Este término corresponde a las relaciones más o menos integrativas que aparecen entre dos disciplinas.  Tales conexiones están manifiestas en las denominaciones de las mismas, como por ejemplo: bio-química; astro-física; geo-física; etno-botánica;  genética de las poblaciones; … y hasta la discutida socio-biologia.</p>
            <p>Paradójicamente, también las interdisciplinas suelen transformarse en nuevas disciplinas hiper-especializadas.</p>
            <h3>Multidisciplinariedad</h3>
            <p>El término refleja la siempre creciente necesidad de colaboración entre numerosos especialistas disciplinarios para el manejo de situaciones que ponen en juego tanto tecnologias como intereses diversos … y frecuentemente divergentes.   En todos los casos se trata  de compatibilizar y armonizar aspectos potencialmente conflictivos o poco compatibles entre sí. Un ejemplo característico es la construcción de una represa con una central  hidroeléctrica apuntando a la industrialización de una zona pobre,  pero de gran importancia ecológica.</p>
            <p>El problema típico que suele aparecer es la incomprensión (el “diálogo de sordos”) entre especialistas, cada cual usando su propia jerga profesional y al mismo tiempo convencidos de la decisiva y suprema importancia de su disciplina en el caso considerado.</p>
            <p>Las conclusiones suelen ser confusas y ambiguas y los decisores finales adoptan las que más les parece convenir en función de sus propios conocimientos, metas e intereses (no necesariamente bien entendidos)</p>
            <p>Ejemplos de situaciones complejas:</p>
            <ul>
                <li>la droga y sus múltiples aspectos: han desaparecido las barreras contra el consumo, contra la producción, contra el tráfico y contra la impunidad</li>
                <li>la evolución política: un caso positivo, aunque de evolución muy lenta, en crisis, es la Unión Europea;  un sistema mal integrado que terminó por no funcionar y se deshizo: la Unión Soviética; un caso de integración muy imperfecta: la América Latina.</li>
            </ul>
            <h2>3. Significado e implicancias de la Transdisciplinariedad</h2>
            <p>El sistemista inglés Peter Checkland señaló hace más de 40 años que “lo que necesitamos no son grupos interdisciplinarios, sino conceptos transdisciplinarios, o sea conceptos que sirvan para unificar el conocimiento por ser aplicables en áreas que superan las trincheras que tradicionalmente delimitan las fronteras académicas”</p>
            <p>Veamos un ejemplo.</p>
            <p>Son numerosas las entidades naturales que poseen reguladores – también naturales – de algunos de sus procesos o funciones. Nosotros mismos, como seres biológicos, tenemos diversas regulaciones, por ejemplo en el caso de nuestra presión sanguínea, de nuestra temperatura corporal, de nuestro ritmo respiratorio y cardíaco, del nivel glucémico en la sangre, etc…</p>
            <p>Regulaciones similares – y los dispositivos correspondientes –  existen en todos los seres vivientes (animales y vegetales), que deben adaptarse y readaptarse sin cesar,  a condiciones variables de entorno y de equilibrio interno.</p>
            <p>Todos los reguladores tienen el mismo mecanismo básico, o sea la retroacción por retroalimentación (el “feedback”) del efecto resultante del proceso, observado y medido en cada instante, sobre el ritmo de la función o del proceso mismo.</p>
            <p>Por ejemplo, el corazón está equipado con  un dispositivo nervioso acelerador o frenador que responde a la percepción orgánica de la presión sanguínea.</p>
            <p>En síntesis, el principio del feedback es absolutamente general: se trata de la regularización de la actividad (función, proceso) por los resultados de la misma y en correspondencia con una norma existente naturalmente,  o establecida por un agente.</p>
            <p>En este caso de los controles, o sea las regulaciones creadas por el hombre, la “norma” es introducida por el contralor humano en función de un criterio razonado referido a la meta buscada.  Un ejemplo muy conocido es el termostato, otro es el rol del flotador en el tanque de agua.</p>
            <p>El concepto de retro-alimentación (feedback) es por lo tanto un meta-concepto:  Reúne las características comunes de múltiples ejemplos específicos de retro-alimentación.</p>
            <p>Teniendo esto en cuenta, es posible establecer un modelo general del regulador y proceso de regulación que puede resumirse como sigue:</p>
            <ol style={{listStyle:"lower-alpha"}}>
                <li>Debe haber un monitor que mide el flujo, la velocidad, la presión, o en general el ritmo, valor o nivel del proceso;</li>
                <li>Debe especificarse un valor de referencia, o sea el óptimo que es necesario buscar, obtener y mantener;</li>
                <li>Debe haber un medidor permanente  de las diferencias entre los sucesivos valores  observados y el valor óptimo;</li>
                <li>El medidor de diferencias debe estar conectado a un efector que convierta la diferencia observada en disparador de la correción necesaria;</li>
                <li>Después de tener lugar la corrección,  se debe volver al principio y monitorear el resultado, tornando así cíclica la regulación.</li>
            </ol>
            <h2>4. Los grandes conceptos y modelos sistémicos y cibernéticos    transdisciplinarios</h2>
            <p>Los conceptos generalistas sistémicos y cibernéticos tienen significado para varias disciplinas, diferentes unas de otras, vista la noción de regulación (en su sentido general)</p>
            <p>Su utilidad reside en:</p>
            <ul>
                <li>capacitarnos para poder distinguir lo general y fundamental de lo específico y particular; es más significativo que exista la regulación como mecanismo universal, que el tipo de regulación en sí mismo</li>
                <li>proveernos de lo que Joel de Rosnay denominó “El macroscopio”, que permite ver y entender los grandes conjuntos</li>
                <li>proporcionarnos un lenguaje común entre especialistas, lo cual permite establecer una conversación, y lazos entre sí.</li>
            </ul>
            <p>Existen varios modelos transdisciplinarios estructurales o funcionales, que presentan caracteres generales similares y son significativos en varias o muchas aplicaciones específicas</p>
            <p>Pueden citarse:</p>
            <p>La noción de sistema en general:</p>
            <p>Los sistemas abiertos y cerrados, selectivamente y en el tiempo</p>
            <p>La diferenciación de la entidad o sistema respecto de un entorno, específico y significativo y la existencia de una clara  separación entre ambos (frontera)</p>
            <p>La autonomía; el sistema se auto-determina, controla sus intercambios con su entorno, mediante regulaciones y reservas</p>
            <p>La variedad o sea un gran número de estados posibles, condición de su adaptabilidad</p>
            <p>Las constricciones propias del sistema restringen sus posibilidades</p>
            <p>Los ingresos que recibe son característicos y también lo son los egresos que produce; tales ingresos y egresos son de energía, materia, e información</p>
            <p>La taxonomía de los subsistemas estructurales y funcionales de Miller</p>
            <p>Los distintos modos de crecimiento (p.ej. lineal, exponencial, asintótico)</p>
            <p>La estabilidad dinámica entre límites, u homeostasis, mediante retroalimentaciones positivas, negativas o compensadas</p>
            <p>Los ciclos de actividad con fluctuaciones</p>
            <p>Las estructuras y funciones fractales</p>
            <p>La autopoiesis (auto-reproducción) por recursividad</p>
            <p>La estructuración por disipación de energía</p>
            <p>Las catástrofes ( o discontinuidades bruscas y rupturas de trayectorias)</p>
            <p>La equifinalidad (llegando al mismo resultado por caminos diferentes)</p>
            <p>La duración limitada como entidad identificable</p>
            <p>El aura (o rastro del sistema desaparecido)</p>
            <h2>Otros conceptos sistémicos</h2>
            <p>La emergencia, por disipación estructurante, con bifurcaciones generalmente imprevisibles</p>
            <p>Los tipos de estabilidad: asintótica, cíclica, caótica</p>
            <p>La simultaneidad y los límites al determinismo</p>
            <p>La información: cuantitativa (en número de bits), cualitativa (significaciones, semántica, lenguajes)</p>
            <p>La comunicación: en el aspecto físico: ruido, redundancia, canal, código; en el aspecto humano: lenguajes, códigos semánticos</p>
            <p>La producción de entropía</p>
            <p>La criticalidad autoorganizada en los sistemas complejos</p>
            <p>La stigmergía: relaciones del sistema con sus componentes</p>
            <p>El síndrome general de adaptación y la desorganización del sistema</p>
            <p>Estos conceptos y modelos transdisciplinarios se pueden describir como herramientas para interrogar e interpretar estados y comportamientos de cualquier clase de entidades organizadas.</p>
            <p>En el estudio del sistema complejo, pueden tener lugar preguntas tales como:</p>
            <p>está regulado el sistema?</p>
            <p>dónde se encuentran sus regulaciones: son internas, están en su entorno?</p>
            <p>cuáles son las funciones reguladas?</p>
            <p>cómo funcionan las regulaciones? (qué es lo que crece, decrece, cuándo, cómo?</p>
            <p>Funciona correctamente, tal  o cual regulador?, o sea, mantiene al sistema en buen estado y activo?</p>
            <p>Al modelizar, podemos hacer lo que A. Einstein llamaba experiencias mentales, como por ejemplo:  qué pasaría si…? (si no hiciéramos nada o si interviniéramos de tal o cual forma)</p>
            <p>Los conceptos y modelos transdisciplinarios cubren un vasto dominio de fenómenos complejos que no pueden interpretarse usando los modelos tradicionales,  como por ejemplo el determinismo causal lineal o el análisis reduccionista.</p>
            <p>En definitiva, la Transdisciplinariedad es  base fundamental para  la Sistémica y la Cibernética, como actitud de búsqueda de la comprensión de la complejidad.</p>
            <p>Su aplicación  a las entidades y fenómenos que abarcan e integran múltiples aspectos interconectados en el espacio e interactuantes en el tiempo,  en niveles que van de lo nano- y microscópico, a lo macro- y megascópico, es de fundamental importancia</p>
            <h2>Referencias</h2>
            <p>Ackoff R. (1995) Whole-ing the parts and righting the wrongs – Systems Research 12 (1), 43-46 [Management suffers from panacea overload and common sense understanding deficit]</p>
            <p>Ashby W.Ross (1956) An Introduction to Cybernetics. Chapman & Hall, London.  [A must. The real basic book on the subject]</p>
            <p>Banathy B.& Rodriguez Delgado R. (1993) International Systems Science Handbook – Systemic Publications, Madrid.(now probably available only from Univ. of Valencia, Spain) [An important collection of papers, some of them very fundamental]</p>
            <p>Bertalanffy L. von (1932) Theoretische Biologie.  Bornträger, Berlin.  [The end of vitalism; The beginnings of organismic biology]</p>
            <p>Bertalanffy L. von (1950) An outline of General Systems Theory.  British Journal of. Philosophy of Science. I(2)   [The original “manifest” by the founding father]</p>
            <p>Boden M. The creative mind: myths and mechanisms. Basic books, New York, 1991. Boulding K.(1974) The World as a Total System. Sage, Beverly Hills, CA. [A very  early and lucid view of what is now coming into existence]</p>
            <p>Brentano F.von (1944) Psychologie du point de vue empirique.  Aubier, Paris. (German original, 1911)  [Basic on intentionality in perception]</p>
            <p>Cannon,W. (1963) The Wisdom of the Body. Norton, New York. (Original, 1932)   [Understanding of biological self-regulation as the condition for health]</p>
            <p>Checkland P.(1981) Systems Thinking, Systems Practice  Wiley, New York. [Practice and philosophy of systemic management]</p>
            <p>Conway J.(1976) On Numbers and Games- Academic Press, London</p>
            <p>[In search of the basic universal rules of interactions among elements… through entertaining games]</p>
            <p>Eigen M. & Schuster P.(1979) The Hypercycle. Springer, Berlin</p>
            <p>[How systems reproduce themselves in a complex cyclical way]</p>
            <p>Eigen M. & Winkler R.(1975) .Das Spiel. Piper, München. (in English: Laws of the Game  Knopf, New York)  [Also in search of the basic rules of interactions among elements]</p>
            <p>Espagnat B. (d´) (1985)  Une incertaine réalité.  Gauthier Villars, Paris</p>
            <p>François Ch. (1989) Unperceived correlations in man-made disasters 12th Congress of the International Cybernetics Association, Namur, Belgium  [On uncoordinated action of many specialists in complex issues as a cause of disasters]</p>
            <p>François Ch.(1997) International Encyclopedia of Systems and Cybernetics”. K.G.Saur Verlag, Munich  [3000 entries and 1200 bibliographical references on all aspects of the subject]</p>
            <p>François Ch.(2004) .  2nd. Edition revised and much enlarged. ( 2 volumes) K.G. Saur Verlag, Munich</p>
            <p>Korzybski A.(1950) Science and Sanity (2nd Ed.) Institute for General Semantics, Lakeville, Conn. [A deep exploration of psycho-logics and psycho-pathologies in observation and in language use]</p>
            <p>Laville Ch. (1950) Mécanismes biologiques de l’Atome à l’Etre vivant Dunod, Paris. [An impressive synthesis on the general rules of organization in nature]</p>
            <p>Linstone H.(Ed.)(1984) Multiple Perspectives for Decision Making North Holland, New York. [Getting out of the ruts of sclerosed thinking]</p>
            <p>Mac Kay D.(1969) Information, Mechanism and Meaning. MIT Press, Cambridge, MA. [Communication is not information but only its vehicle. The meanings are constructed  in the minds]</p>
            <p>Maruyama M.(1963) The 2nd Cybernetics: Deviation-amplifying mutual causal processes American Scientist 51 (2) [Organizing or destructive effects of positive feedback]</p>
            <p>Maturana H. & Varela F.(Eds)(1980)Autopoiesis and Cognition Reidel, Boston, MA. [A basic collection on self-reproduction by recursivity in systems]</p>
            <p>McCulloch, W. & Pitts, W.A (1943) Logical calculus of the ideas immanent in nervous activity. Bull. of Mathematical Biophysics (5), 1943  [Pioneer work toward a new understanding of the brain]</p>
            <p>McNeil D.H. (1999) Construing Systemicity: A sampler in Systems and Cybernetics. McNeil, 726 Washington Blvd, #107, Williamsport, PA 17701, USA [The most basic and recent general formulation of systemics and cybernetics in existence]</p>
            <p>Miller J.G.(1978) Living Systems. Mc Graw Hill, New York. [Fundamental on living organisms structures and function at all levels]</p>
            <p>Neumann J.von (1956) The general and logical theory of automata.  Simon & Schuster, New York.</p>
            <p>Neumann J.von (1966) Theory of self-reproducing automata. Univ. of Illinois Pres, Urbana, Ill. [Main  seed of all later kinds of future developments of automata]</p>
            <p>Prigogine I.(1980) From Being to Becoming: Time and Complexity in the Physical Sciences. W.H.Freeman, San Francisco. [The fundamental work on the subject. Prigogine was possibly the most important scientist of the 20th Century, even more than Einstein, mainly because of the present and future potential impact of his work on human sciences]</p>
            <p>Rodriguez Delgado R.(1997) Del Universo al Ser Humano. Mc Graw Hill de España, Madrid.</p>
            <p>[Wide embracing views by the “father” of systemics in Spanish speaking world]</p>
            <p>Shannon C.& Weaver W.(1948) The Mathematical Theory of Communication. Univ. of Illinois Press, Urbana. [Basic on the subject of physical conditions of communication -not information- as it makes nearly no significant  reference to meaning]</p>
            <p>Simon H. (1969) The Science of the Artificial. MIT Press, Cambridge, Mass. [General on man-constructed artefacts and systems]</p>
            <p>Thompson d’Arcy W.(1952) On Growth and Form. Cambridge University Press, London.(Original edition, 1916) [Genial precursor work on forms as generated by fields of energy]</p>
            <p>Troncale L. (1982) Linkages propositions between principal systems concepts.  Proc. 26th Annual Meeting SGSR</p>
            <p>Turing A.(1950) Computing Machines and Intelligence. Mind, 59. [The original basic work on the conditions of computing]</p>
            <p>von Foerster H.(1981) Observing Systems. Intersystems Publ., Seaside, CA.  [A collection of earlier papers who inspired autopoiesis,  and a sharp critical view on the delusions of “objectivity”]</p>
            <p>Walliser B.(1977) Systèmes et Modèles.  Le Seuil, Paris.  [A good introduction in french to the subject]</p>
            <p>Warfield J.(1989) Societal Systems. Intersystems Publ., Salinas, CA  [The views of a social design expert on human societies]</p>
            <p>Wiener N.(1948) Cybernetics: Control and Communication in the Animal and the Machine. Hermann, Paris. [The foundational work on cybernetics]</p>
            <p>Wiener N. (1954) The human use of human beings: Cybernetics and Society.  Anchor, Doubleday, New York [A first essay on the meaning of cybernetics for human sciences.  The questionable term “use” may have originated a deep mistrust of cybernetics in the mind of some psychologists and sociologists]</p>
        </div>
    )
}